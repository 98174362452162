import React, { useEffect } from 'react';
import { Router, Switch, Route } from 'react-router';
import { useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import { bindActionCreators } from 'redux';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

import './app.scss';
import './main.css';
import MainPage from 'src/components/MainPage';
import LoginPage from 'src/components/auth/LoginPage';
import CreateAccountPage from 'src/components/auth/CreateAccount';
import store from './modules/store';
import history from 'src/history';
import setupAxiosInterceptors from "src/config/axios-interceptor";
import { clearAuthentication, restoreSession } from 'src/modules/actions/authentication.actions';
import { notifyForbidden } from 'src/modules/actions/application.actions';
import AuthorRoutes from 'src/components/author/AuthorRoutes';
import ProfilePage from 'src/components/author/ProfilePage';
import NotFoundPage from 'src/components/_shared/NotFound';
import AuthorPage from 'src/components/reader/AuthorPage';
import MyLibraryPage from 'src/components/reader/MyLibraryPage';
import PrivateRoute from 'src/components/auth/PrivateRoute';
import ReaderRoutes from 'src/components/reader/ReaderRoutes';
import SeeAllPage from 'src/components/SeeAllPage';
import SearchResultsPage from 'src/components/SearchResultsPage';
import ApplyCouponPage from 'src/components/ApplyCouponPage';
import TagsHome from 'src/components/TagsHome';
import GenresPage from 'src/components/GenresPage'
import ModalWindow from 'src/components/_shared/ModalWindow';
import CompetitionPage from 'src/components/CompetitionPage'
import {
  DEFAULT_TITLE,
  DEFAULT_META_DESCRIPTION,
  DEFAULT_META_IMAGE
} from 'src/config/constants';
import NameRequired from 'src/components/_shared/NameRequired';
import { PrivacyPolicyPage, ContentGuidelinesPage, TermsOfServicePage, Competition } from 'src/components/static-pages';
import EarningsPage from 'src/components/EarningsPage';
import PayoutsConfigured from 'src/components/PayoutsConfiguredPage';
import ForgotPasswordPage from 'src/components/auth/ForgotPasswordPage';
import ForgotPasswordEmailSentPage from 'src/components/auth/ForgotPasswordEmailSent';
import RestorePasswordPage from 'src/components/auth/RestorePasswordPage';
import ListenPage from 'src/components/reader/ListenPage';
import NotificationsPage from 'src/components/NotificationsPage';
import Blog from 'src/components/blog/Blog';
import BlogPost from 'src/components/blog/BlogPost';
import EditorBlogPosts from './components/blog/EditorBlogPosts';
import EditBlogPost from './components/blog/EditBlogPost';
import URLParamParser from './modules/controllers/URLParamParser';
import BecomeWriterPage from 'src/components/BecomeWriterPage';
import ContentPackage from 'src/components/ContentPackage'
import * as banners from 'src/modules/api/banners.api';
import * as manageCoupons from 'src/modules/api/manageCoupons.api';
import * as Book from 'src/modules/model/book';
import { VISUAL_STYLE } from './components/ContentPackage/List';
import { Button } from 'carbon-components-react';
import * as cookiesApi from 'src/modules/api/cookie.api';
import { RES_PATH } from 'src/config/environment';
import { Link } from 'react-router-dom';
import { Download24 } from '@carbon/icons-react';
import SmartBanner from 'react-smartbanner';
import 'react-smartbanner/dist/main.css';
 
const reduxStore = store;

const actions = bindActionCreators({ clearAuthentication, restoreSession, notifyForbidden }, reduxStore.dispatch);
setupAxiosInterceptors(() => actions.clearAuthentication('login.error.unauthorized'), actions.notifyForbidden);

actions.restoreSession();

const collectGA = location => {
  const page = location.pathname;
  ReactGA.set({
    page
  });
  ReactGA.pageview(page);
}
history.listen(collectGA);
collectGA(history.location);

const bannerProperties = [
  {
    title: "User Type*", type: "dropdown", id: "userType", 
    default: "all",
    dropdown: {
      options: [
        { id: "all", labelText: "All", value: "all" },
        { id: "reader", labelText: "Readers", value: "reader" },
        { id: "writer", labelText: "Writer", value: "writer" }
      ]
    }
  },
  {
    title: "Index*", type: "dropdown", id: "index", 
    default: 0,
    dropdown: {
      options: [
        { id: '1', labelText: "1", value: 1 },
        { id: '2', labelText: "2", value: 2 },
        { id: '3', labelText: "3", value: 3 },
        { id: '4', labelText: "4", value: 4 },
        { id: '5', labelText: "5", value: 5 },
        { id: '6', labelText: "6", value: 6 },
      ]
    }
  },
  { title: "URL*", type: "text", id: "url" },
  { 
    title: "Image", type: "image", id: "image",
    convertTo: (imageData) => { 
      if(imageData.startsWith("data")){
        return ({ data: imageData.split(',')[1], type: 'jpg' })
      } else {
        return imageData
      }
    }
  }
]


const couponProperties = [
  {
    title: "Maximum Rating*", type: "dropdown", id: "maxRating", 
    default: "pg13",
    dropdown: {
      options: Book.RATING_OPTIONS
    }
  },
  { title: "Count*", type: "text", id: "count",  default: 1}
]

function App() {
  return (
    <>
      <Helmet>
        <meta property="og:title" content={DEFAULT_TITLE} />
        <meta property="og:description" content={DEFAULT_META_DESCRIPTION} />
        <meta property="og:image" content={DEFAULT_META_IMAGE} />
      </Helmet>
      <SmartBanner title={'Fictionate.me'} />
      <Provider store={reduxStore}>
        <Router history={history}>
          <ScrollToTop/>
          <URLParamParser/>
          <Switch>
            <Route exact path="/" component={MainPage}/>
            <Route path="/login" component={LoginPage} />
            <Route path="/register" component={CreateAccountPage} />
            <Route path="/my-books" component={AuthorRoutes} />
            <ContentPackage 
              path="/admin/banners" 
              getAll={banners.getAll}
              get={banners.get}
              post={banners.post}
              put={banners.put}
              remove={banners.remove}
              title="Banners"
              addTitle="Add Banner"
              properties = {bannerProperties}
            />
            <ContentPackage
              path="/admin/managedCoupons" 
              getAll={manageCoupons.getManageCoupons}
              post={manageCoupons.post}
              put={manageCoupons.put}
              remove={manageCoupons.remove}
              title="Student Subscription Codes"
              addTitle="Create Code"
              properties={couponProperties}
              style={VISUAL_STYLE.LIST}
              actions={(            
                <a href={`${RES_PATH}/coupons/download?AuthToken=${cookiesApi.getAuthToken()}`} >
                <Button kind='tertiary'
                  style={{marginRight:'16px', paddingRight: '16px'}}
                ><Download24 /></Button>
                </a>
              )}
              list={{
                colums: ["Type", "Rating", "Code", "Redeemed"],
                rowComponents: (item)=>[
                  item?.content?.type, 
                  Book.RATING_OPTIONS
                    .filter((option) => 
                    option.id === item?.content?.content?.maxRating
                    )[0]?.labelText, 
                  item.shortId,
                  (item.useCount > 0) ? "Not Redeemed" : "Redeemed"]
              }}
            />
            <PrivateRoute path="/notifications" component={NotificationsPage} />
            <PrivateRoute path="/profile" component={ProfilePage} />
            <PrivateRoute path="/become" component={ProfilePage} />
            <PrivateRoute path="/earnings" component={EarningsPage} />
            <PrivateRoute path="/payouts_configured" component={PayoutsConfigured} />
            <Route path="/writers/:uuid" component={AuthorPage} />
            <Route path="/books" component={ReaderRoutes} />
            <Route path="/listen/:bookUuid/:chapterUuid?" component={ListenPage}/>
            <Route path="/notFound" component={NotFoundPage} />
            <Route path="/seeAll/:id" component={SeeAllPage} />
            <Route path="/results" component={SearchResultsPage} />
            <Route path="/coupon" component={ApplyCouponPage} />
            <Route path="/list/:tag" component={CompetitionPage} />
            <Route path="/tags/:tag" component={TagsHome} />
            <Route path="/genres" component={GenresPage} />
            <PrivateRoute path="/library" component={MyLibraryPage} />
            <Route path="/forgot" component={ForgotPasswordPage} />
            <Route path="/password-sent" component={ForgotPasswordEmailSentPage} />
            <Route path="/restore" component={RestorePasswordPage} />

            <Route path="/s/privacy-policy" component={PrivacyPolicyPage} />
            <Route path="/s/terms-of-service" component={TermsOfServicePage} />
            <Route path="/s/content-guidelines" component={ContentGuidelinesPage} />
            <Route path="/s/competition" component={Competition} />
            <Route path="/s/whats-in-here-for-writers" component={BecomeWriterPage} />

            <Route path="/p/:id" render={(props) => <BlogPost surface="static" {...props} /> } />
            <Route path="/blog/:id" component={BlogPost} />
            <Route path="/blog" component={Blog} />
            <PrivateRoute path="/me/posts/add" component={EditBlogPost} />
            <PrivateRoute path="/me/posts/:uuid/edit" component={EditBlogPost} />
            <PrivateRoute path="/me/posts" component={EditorBlogPosts} />
            <Route component={NotFoundPage} />
          </Switch>
        </Router>
        <ModalWindow />
        <NameRequired />
      </Provider>
    </>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;

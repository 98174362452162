import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Content } from 'carbon-components-react/lib/components/UIShell';
import { ToastNotification } from 'carbon-components-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import cn from 'classnames';
import classes from './layout.module.scss';
import Header from 'src/components/Header';
import statuses from 'src/modules/model/status';
import Forbidden from 'src/components/_shared/Forbidden';
import { BANNERS_ENABLED } from 'src/config/constants';
import Banner from 'src/components/MainPage/Banner';
import { hideInfoMessage, clearStatus } from 'src/modules/actions/application.actions';
import { getTheme } from 'src/modules/actions/theme.actions';

import './styles.css';

export const AppLayout = props => {
  const { style, infoMessage, fullScreen, status, children, isAuthenticated, banners, showBanners, ...actions } = props
  const { clearStatus, hideInfoMessage } = actions;
  const theme = getTheme(props.theme)

  useEffect(() => {
    return () => {
      clearStatus();
      hideInfoMessage();
    }
  }, [clearStatus, hideInfoMessage]);

  return (
    <>
      <Header fullScreen={fullScreen}/>
      {showBanners && <Banner banners={banners}/>}
      <Content className={cn(
        classes.mainLayout,
        {
          mt0: fullScreen || (
            showBanners && BANNERS_ENABLED && banners && banners.length > 0
          )
        },
        theme.contentColor
        )}
        style={style}
      >
        {!status && children}
        {status && status === statuses.FORBIDDEN && <Forbidden/>}

        {infoMessage &&
        <ToastNotification
          kind="success"
          lowContrast
          title="Success"
          caption={infoMessage}
          onCloseButtonClick={actions.hideInfoMessage}
          style={{ position: 'absolute', top: 0, right: 0 }}
        />
        }
      </Content>
    </>
  )
};

AppLayout.defaultProps = {
  showBanners: false
};

AppLayout.propTypes = {
  infoMessage: PropTypes.string,
  fullScreen: PropTypes.bool,
  children: PropTypes.any,

  hideInfoMessage: PropTypes.func,
  clearStatus: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  showBanners: PropTypes.bool
};

export default connect(
  state => ({
    infoMessage: state.application.infoMessage,
    fullScreen: state.application.fullScreen,
    status: state.application.status,
    isAuthenticated: state.authentication.isAuthenticated,
    banners: state.home.banners
  }),
  dispatch => bindActionCreators({ hideInfoMessage, clearStatus }, dispatch)
)(AppLayout);

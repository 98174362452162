import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classes from './styles.module.scss';
import { resizeImage } from 'src/modules/api/utils';

export const InputImage = ({ input, initialSrc, width, height, scaledWidth, scaledHeight, imagePlaceholder, saveOriginalProportion }) => {

  const [image, setImage] = useState(null);
  const defaultImage = process.env.PUBLIC_URL + '/' + imagePlaceholder;

  const handleChange = async e => {
    const file = e.target?.files?.[0];
    if (file) {
      resizeImage(file, scaledWidth, scaledHeight).then((data)=>{
        setImage(data);
        input.onChange(data);
      })
    }
  };

  return (
    <div>

      <label className={classes.inputLabel}>
        <input
          {...input}
          type='file'
          onChange={handleChange}
          className={classes.inputFile}
          value={undefined}
          accept="image/x-png,image/jpeg"
        />

        <div style={{
          width,
          height,
          backgroundImage: `url(${image || initialSrc || defaultImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          cursor: 'pointer'
        }}
        />
      </label>

    </div>
  )
};

InputImage.defaultProps = {
  imagePlaceholder: 'upload.svg',
  saveOriginalProportion: false
}

InputImage.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  scaledWidth: PropTypes.number.isRequired,
  scaledHeight: PropTypes.number.isRequired,
  imagePlaceholder: PropTypes.string,
  saveOriginalProportion: PropTypes.bool
}

export default InputImage;

export function findCurrentChapter(book, currentChapterExternal) {
  const chapters = book?.chapters;
  return currentChapterExternal || book?.progress?.chapter || chapters?.[0]?.uuid;
}

export function getNextChapter(chapters, currentChapter) {
  return getNextChapterObject(chapters, currentChapter)?.uuid;
}

export function getPrevChapter(chapters, currentChapter) {
  return getPrevChapterObject(chapters, currentChapter).uuid
}

export function getNextChapterObject(chapters, currentChapter) {
  const i = chapters.findIndex(c => c.uuid === currentChapter);
  if (i >= 0 && chapters.length > i + 1) return chapters[i + 1];
  else return null;
}

export function getPrevChapterObject(chapters, currentChapter) {
  const i = chapters.findIndex(c => c.uuid === currentChapter);
  if (i > 0) return chapters[i - 1];
  else return null;
}

const SEC = 1;
const MIN = SEC * 60;
const HOUR =  MIN * 60;
export function formatDuration(num) {
  let str = "";
  let rest = num;
  const h = Math.floor(num / HOUR);
  if (h > 0) str = h + ":";
  rest -= (h * HOUR);
  const m = Math.floor(rest / MIN);
  if (m > 0) {
    if (m < 10 && h > 0) str += "0" + m + ":";
    else str += m + ":";
  } else {
    if (h > 0) {
      str += "00:";
    } else {
      str += "0:";
    }
  }
  rest -= (m * MIN);
  const s = Math.floor(rest / SEC);
  if (s > 0) {
    if (s < 10) str += "0" + s;
    else str += s;
  } else {
    str += "00";
  }
  return str
}

import axios from 'axios';
import * as cookiesApi from 'src/modules/api/cookie.api';
import ReactGA from 'react-ga';

import { ACTION_TYPES } from 'src/modules/reducers/authentication.reducer';
import { displayInfoMessage } from 'src/modules/actions/application.actions';
import * as me from 'src/modules/actions/me.actions';
import history from 'src/history';
import * as ls from 'src/modules/api/local-storage.api';
import { FORM_ERROR } from 'final-form'
import { getAuthHeader } from 'src/modules/api/utils';
import { registrationMessages, loginMessages } from './error.messages';
import { ErrAuthMessage } from 'src/modules/model/auth'

export const displayAuthMessage = messageInstance => ({ type: ACTION_TYPES.MESSAGE, messageInstance });

export const displayAuthError = (message) => {
  return displayAuthMessage(new ErrAuthMessage(message));
}

export const clearAuthentication = messageKey => (dispatch) => {
  dispatch(displayAuthMessage(new ErrAuthMessage(messageKey)));
  dispatch({
    type: ACTION_TYPES.CLEAR_AUTH
  });
  ls.removeAccount();
  cookiesApi.deleteToken();
  history.push('/');
};

export const hideMessages = () => ({ type: ACTION_TYPES.MESSAGE, messageInstance: null })

function getAuthenticated(locationState, gaAction, authRequest) {
  return async dispatch => {
    try {
      dispatch({ type: ACTION_TYPES.LOGIN_ACCOUNT_PENDING });
      const response = await authRequest();
      const data = response.data;
      const authtoken = response.headers[cookiesApi.TOKEN_KEY];
      const account = { ...data };
      dispatch({ type: ACTION_TYPES.LOGIN_ACCOUNT_FULFILLED, account });
      ls.saveAccount(account);
      cookiesApi.setToken(authtoken);
      ReactGA.set({ userId: account.uuid });
      ReactGA.event({ category: 'user', action: gaAction});

      dispatch(me.getProfile());

      const path = (locationState && locationState.from) || '/';
      history.push(path);

    } catch (e) {
      dispatch({
        type: ACTION_TYPES.LOGIN_ACCOUNT_REJECTED,
        messageInstance: new ErrAuthMessage(loginMessages(e?.response?.data?.error))
      });
    }
  }
}

export function verify() {
  return async dispatch => {
    const account = ls.getAccount();
    account.verified = true
    ls.updateAccount(account)
    restoreSession()
  };
}

export function authenticate(login, password, gaAction, locationState) {
  return async dispatch => {
    dispatch(getAuthenticated(locationState, gaAction, () =>
      axios.post('/login', {login, password})));
  };
}

export function fbAuthenticate(email, accessToken, imageUrl, userId, gaAction, locationState) {
  return async dispatch => {
    console.log("fbAuthenticate");
    dispatch(getAuthenticated(locationState, gaAction, () =>
      axios.post('/fblogin', {email, accessToken, imageUrl, userId})));
  };
}

export function glAuthenticate(idToken, gaAction, locationState) {
  return async dispatch => {
    console.log("glAuthenticate");
    dispatch(getAuthenticated(locationState, gaAction, () =>
      axios.post('/gllogin', {idToken})));
  };
}

function initGA(userId) {
  const gaOpts = {
    debug: false,
    gaOptions: {
      cookieDomain: "none"
    }
  };

  if (userId) {
    gaOpts.gaOptions.userId = userId;
  }

  ReactGA.initialize('UA-167427530-1', gaOpts);
}

export function restoreSession() {
  return dispatch => {
    let userId = null;

    if (cookiesApi.isAuthenticated()) {
      const account = ls.getAccount();
      if (account) {
        userId = account.uuid;
        dispatch({
          type: ACTION_TYPES.RESTORE_SESSION,
          session: account
        });

        dispatch(me.getProfile());
      }
    }
    initGA(userId);
  }
}

export function createAccount({ name, email, password, isWriter }, code, badge, gaAction, locationState) {
  return async dispatch => {
    try {
      dispatch({ type: ACTION_TYPES.CREATE_ACCOUNT_PENDING });
      let uri = isWriter ? '/writers' : '/users';
      const account = { name, photo: null, email, password };
      if (code && badge) {
        account.badges = [badge];
        account.code = code;
      }
      await axios.post(uri, account);

      dispatch({ type: ACTION_TYPES.CREATE_ACCOUNT_FULFILLED });

      authenticate(email, password, gaAction, locationState)(dispatch);
      dispatch(displayInfoMessage("Welcome "  + name));

    } catch (e) {
      dispatch({
        type: ACTION_TYPES.CREATE_ACCOUNT_REJECTED,
        messageInstance: new ErrAuthMessage(registrationMessages(e?.response?.data?.error))
      });
    }
  }
}

export function updateProfile(accountUuid, values, initialPhoto, path) {
  return async (dispatch, getState) => {

    const dispatchError = message => {
      dispatch({ type: ACTION_TYPES.UPDATE_PROFILE_REJECTED });
      return { [FORM_ERROR]: message };
    };

    const validateImage = ({ photo }) => {
      if (initialPhoto) return null;

      if (!photo) {
        return 'Image required';
      }
    };

    const makeAccountData = ({ name, about, email, photo: imageData }) => {
      const obj = {
        name,
        about,
        email
      };

      if (imageData) {
        obj.photo = {
          data: imageData.split(',')[1],
          type: 'jpg'
        }
      }

      return obj;
    };

    try {
      dispatch({ type: ACTION_TYPES.UPDATE_PROFILE_PENDING });
      const error = validateImage(values);
      if (error) {
        return dispatchError(error);
      }

      const becomeWriter = path.endsWith('become');
      const url = (getState().authentication.account.writer || becomeWriter) ? `/writers/${accountUuid}` : `/users/${accountUuid}`;
      const data = makeAccountData(values);
      await axios.put(url, data, getAuthHeader());
      const response = await axios.get(`/me`, getAuthHeader());

      const account = response.data;
      dispatch({ type: ACTION_TYPES.UPDATE_PROFILE_FULFILLED, account });
      ls.updateAccount(account);
      history.push(account.writer ? '/my-books' : '/');

    } catch (e) {
      console.log(e);
      dispatch({ type: ACTION_TYPES.UPDATE_PROFILE_REJECTED });
    }
  }
}

export const setIsAuthorSelected = (flag) => ({ type: ACTION_TYPES.SET_IS_AUTHOR_SELECTED, isAuthor: flag });

export const updateUserName = (userName, onError, onSuccess) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.UPDATE_PROFILE_PENDING });
      const _account = getState().authentication.account;
      const uuid = _account.uuid;
      const url = _account.writer ? `/writers/${uuid}` : `/users/${uuid}`;
      const data ={ name: userName };
      await axios.put(url, data, getAuthHeader());
      const response = await axios.get(`/me`, getAuthHeader());
      const account = response.data;
      dispatch({ type: ACTION_TYPES.UPDATE_PROFILE_FULFILLED, account });
      ls.updateAccount(account);
      onSuccess();
    } catch (e) {
      console.log(e);
      dispatch({ type: ACTION_TYPES.UPDATE_PROFILE_REJECTED });
      onError('Cannot update user name.')
    }
  }
};

export const withMask = (login, uuid) => {
  return async dispatch => {
    
    var object = {}
    if(login) { object.login = login }
    if(uuid) { object.uuid = uuid }
    dispatch(getAuthenticated(null, "login_mask", () =>
      axios.post('/mask', object, getAuthHeader())));
  }
};

export const forgotPassword = async email => {
  try {
    const res = await axios.post('/forgotPassword', { email });
    return res.status === 200;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export const recoverPassword = (email, token, newPassword, errCb) => {
  return async dispatch => {
    try {
      const res = await axios.post('/recoverPassword', { email, token, newPassword });
      if (res.status === 200) {
        authenticate(email, newPassword)(dispatch);
        dispatch(displayInfoMessage("Welcome"));
      } else {
        console.log(res);
        errCb("Unknown error happened");
      }
    } catch (e) {
      errCb(registrationMessages(e?.response?.data?.error));
    }
  }
}

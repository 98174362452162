import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Tag } from 'carbon-components-react';
import { PlayFilledAlt16, PauseFilled16, VolumeDownFilled16 } from '@carbon/icons-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { enablePageScroll } from 'scroll-lock';

import cs from './Playlist.module.scss';
import { redirectToCheckout } from 'src/modules/actions/payment.actions';
import { formatDuration } from './chapters.util';
import {
  isChapterAvailable,
  isChapterPriceTier, priceAmount
} from 'src/modules/model/book';

const timeLabel = (chapter) => {
    if(chapter?.audioState){
      switch(chapter?.audioState?.state) {
        case "waiting":
          return (<Tag type='cool-gray' style={{marginLeft: '0.5rem'}}>Waiting...</Tag>)
        case "inProgress":
          return (<Tag type='teal' style={{marginLeft: '0.5rem'}}>Processing {Math.round(chapter?.audioState?.progress * 100.0)}%</Tag>)
        case "succeeded":
          return formatDuration(chapter?.audio?.duration)
        case "failed":
          return (<Tag type='cool-gray' style={{marginLeft: '0.5rem'}}>Not available</Tag>)
        default:
          return (<></>)
      }
    } else if (chapter?.audio?.duration) {
      return formatDuration(chapter.audio.duration)
    } else {
      return (<Tag type='cool-gray' style={{marginLeft: '0.5rem'}}>Not Generated</Tag>)
    }
}

const Playlist = ({ className, book, wouldStartPlaying, currentChapter, playing, disabled, isMobile, mode, setMode, redirectToCheckout }) => {
  const chapters = book?.chapters?.filter(c => c.published) ?? [];
  const listRef = React.useRef();

  const _togglePlaying = chapter => {
    if (!disabled) {
      if (chapter.uuid !== currentChapter || !playing) {
        setMode('player');
      }
      wouldStartPlaying(chapter);
    }
  }

  React.useEffect(() => {
    if (listRef.current) {
      enablePageScroll(listRef.current);
    }
  }, [listRef, isMobile, mode]);

  return (
    <div className={classNames(cs.playlistContainer, className)}>
      <div className={cs.chapters}>Chapters</div>
      <div className={classNames(cs.line, { [cs.hiddenLine]: chapters?.[0]?.uuid === currentChapter })}/>
      <div className={cs.list} ref={listRef}>
      {
        chapters.map((chapter, i) => {
          const isCurrent = chapter.uuid === currentChapter;
          const chapterAvailable = isChapterAvailable(book, chapter);
          return (
            <div key={i} className={classNames(cs.chapter, { [cs.current]: isCurrent, [cs.disabled]: disabled })}
                 onClick={() => _togglePlaying(chapter)}
            >
              {playing && isCurrent ?
                <VolumeDownFilled16 className={classNames(cs.speaker, { [cs.unavailable]: !chapterAvailable })}/> :
                <PlayFilledAlt16 className={classNames({ [cs.unavailable]: !chapterAvailable })}/>}
              {playing && isCurrent && <PauseFilled16 className={classNames(cs.pause, { [cs.unavailable]: !chapterAvailable })}/>}
              <div className={cs.chapterName}>
                <div>{chapter.name}</div>
                {chapter.free && <Tag type='green' style={{marginLeft: '0.5rem'}}>free</Tag>}
              </div>
              { !chapter.free && !chapter.purchased && isChapterPriceTier(book) ?
                <Button size='small'
                        kind='ghost'
                        onClick={() => redirectToCheckout(book, chapter)}
                        className={cs.buyButton}
                >
                  ${priceAmount(book)}
                </Button> :

                <div className={cs.duration}>
                  {timeLabel(chapter)}
                </div>
              }
            </div>
          )
        })
      }
      </div>
    </div>
  )
}

Playlist.defaultProps = {
  chapters: []
}

Playlist.propTypes = {
  className: PropTypes.any,
  book: PropTypes.shape({
    chapters: PropTypes.arrayOf(PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
      published: PropTypes.bool
    }))
  }),
  playlist: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    audio: PropTypes.shape({
      path: PropTypes.string,
      duration: PropTypes.number
    }),
    howl: PropTypes.any
  })),
  wouldStartPlaying: PropTypes.func,
  currentChapter: PropTypes.string,
  playing: PropTypes.bool,
  mode: PropTypes.string,
  isMobile: PropTypes.bool,
  setMode: PropTypes.func,
  redirectToCheckout: PropTypes.func.isRequired
}

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ redirectToCheckout }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Playlist);

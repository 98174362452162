import React, {
    useEffect
    } from 'react';
  
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loading } from 'carbon-components-react';

import AppLayout from 'src/layout/AppLayout';
import PageHeader from 'src/components/_shared/PageHeader';
import Content from 'src/components/_shared/Content';

import { fetchBlogPost } from 'src/modules/actions/blog.actions';
import { RES_PATH } from 'src/config/environment';
import { getTheme } from 'src/modules/actions/theme.actions';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import ShareButton from 'src/components/_shared/ShareButton';

export const BlogPost = props => {
  const { loading, match, id, surface, ...actions } = props;
  const { fetchBlogPost } = actions;
  const object = props.content
  const theme = getTheme(props.theme)

  useEffect(() => {
    var link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "/public-styles.css";
  
    document.head.appendChild(link);  
  });

  useEffect(() => {
      fetchBlogPost(match.params.id, surface);
  }, [fetchBlogPost, match, surface]);

  const writersUrl = (uuid) => {
    return `/writers/${uuid}`
  }

  return ( object &&
    <>
      <Helmet>
        {object.name && <meta property="og:title" content={`Fictionate.me: ${object.name}`} />}
        {object.summary && <meta property="og:description" content={object.summary} />}
        {object.image && <meta property="og:image" content={`${RES_PATH}${object.image}`} />}
      </Helmet>
      {loading ? <Loading withOverlay={false} small/> :
        <AppLayout theme={props.theme} style={{font: "unset"}}>
          <PageHeader sharing image={object && object.image && `${RES_PATH}${object.image}`} theme={props.theme} name={object ? object.name : ""} subtitle={(object && object.writer) ? object.writer.name : ""} subtitleLink={writersUrl((object && object.writer) ? object.writer.uuid : "")} />
          <Content theme="light" style={{font: "unset"}} >
            <div className={cn("ck-content", theme.content)} dangerouslySetInnerHTML={{ __html: object.content }} />
            <div style={{width: "100%", textAlign: "center", marginTop: "24px"}}>
              <ShareButton style={{margin: "auto", display: "inline-block"}} image={`${RES_PATH}${object.image}`} title={object.name} url={window.location.href} />
            </div>
          </Content>
        </AppLayout>
    }
    </>
  )
};

BlogPost.propTypes = {
  fetchBlogPost: PropTypes.func.isRequired,
  id: PropTypes.string
};

function mapStateToProps(state) {
  return {
    ...state.blogPost,
    ...state.theme
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchBlogPost }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogPost);

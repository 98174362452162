import React from 'react';
import PT from 'prop-types';
import Button from 'src/components/_shared/DesignV2/Button';
import { TextAlignCenter16 } from '@carbon/icons-react';
import cs from './ReadButton.module.scss';
import classNames from 'classnames';

import history from 'src/history';
import * as Book from 'src/modules/model/book';

const ReadButton = ({ book, onClick, className }) => {

  let chapter = Book.getChapterToRead(book);

  const _onClick = () => {
    if (typeof onClick === 'function') onClick();
    else history.push(`/books/${book.uuid}/${chapter?.uuid}`);
  };

  const show = chapter && (chapter.free || chapter.public || chapter.purchased || book.purchased || Book.isFree(book));

  return (
    <>
      { (show || typeof onClick === 'function') &&
        <Button kind='normal'
                onClick={_onClick}
                className={classNames(cs.readButton, className)}
        >
          Read
          <TextAlignCenter16 />
        </Button>
      }
    </>
  )
};

ReadButton.propTypes = {
  book: PT.shape({
    uuid: PT.string.isRequired,
    purchased: PT.bool,
  }),
  onClick: PT.func
};

export default ReadButton;

import { ACTION_TYPES as BOOKS_ACTIONS } from 'src/modules/reducers/books.reducer';
import JobUpload from 'src/modules/model/job-upload';

export const BOOK_ORDER = {
  LATEST: {
    key: 'LATEST',
    name: 'latest',
    sorter: (a, b) => {
      return a.created - b.created;
    }
  },
  BYNAME: {
    key: 'BYNAME',
    name: 'name',
    sorter: (a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }
  }
};

export const ACTION_TYPES = {
  CREATE_BOOK_PENDING: 'writers/CREATE_BOOK_PENDING',
  CREATE_BOOK_FULFILLED: 'writers/CREATE_BOOK_FULFILLED',
  CREATE_BOOK_REJECTED: 'writers/CREATE_BOOK_FULFILLED',

  FETCH_BOOKS_PENDING: 'writers/FETCH_BOOKS_PENDING',
  FETCH_BOOKS_FULFILLED: 'writers/FETCH_BOOKS_FULFILLED',
  FETCH_BOOKS_REJECTED: 'writers/FETCH_BOOKS_REJECTED',

  UPDATE_BOOK_PENDING: 'writers/UPDATE_BOOK_PENDING',
  UPDATE_BOOK_FULFILLED: 'writers/UPDATE_BOOK_FULFILLED',
  UPDATE_BOOK_REJECTED: 'writers/UPDATE_BOOK_REJECTED',

  FETCH_WRITER_PENDING: 'writers/FETCH_WRITER_PENDING',
  FETCH_WRITER_FULFILLED: 'writers/FETCH_WRITER_FULFILLED',
  FETCH_WRITER_REJECTED: 'writers/FETCH_WRITER_REJECTED',

  BOOKS_SORT: 'writers/BOOKS_SORT',

  UPDATE_JOB: 'writers/UPDATE_JOB',
  REMOVE_JOB: 'writers/REMOVE_JOB'
};

const defaultState = {
  loading: false,
  books: [],
  booksOrder: BOOK_ORDER.LATEST.key,
  jobs: []
};

function sortBooks(books, sorter) {
  const sortedBooks = [...books];
  sortedBooks.sort(sorter);
  return sortedBooks;
}

function deleteBook(state, action) {
  switch (action.type) {
    case BOOKS_ACTIONS.DELETE_BOOK_PENDING:
      return {
        ...state,
        loading: true
      };
    case BOOKS_ACTIONS.DELETE_BOOK_FULFILLED:
      return {
        ...state,
        loading: false,
        books: state.books.filter((item, index) => index !== action.index)
      };
    case BOOKS_ACTIONS.DELETE_BOOK_REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.CREATE_BOOK_PENDING:
      return {
        ...state
      };
    case ACTION_TYPES.CREATE_BOOK_FULFILLED:
      return {
        ...state
      };
    case ACTION_TYPES.CREATE_BOOK_REJECTED:
      return {
        ...state
      };
    case ACTION_TYPES.FETCH_BOOKS_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.FETCH_BOOKS_FULFILLED:
      return {
        ...state,
        loading: false,
        books: action.books,
        jobs: state.jobs.filter(job => job.state === JobUpload.state.UPLOADING).concat(action.jobs)
      };
    case ACTION_TYPES.FETCH_BOOKS_REJECTED:
      return {
        ...state,
        loading: false,
        books: []
      };

    case ACTION_TYPES.UPDATE_BOOK_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.UPDATE_BOOK_FULFILLED:
      return {
        ...state,
        loading: false,
        books: state.books.map(b => {
          if (b.uuid === action.book.uuid) return { ...b, ...action.book }
          else return b;
        })
      };
    case ACTION_TYPES.UPDATE_BOOK_REJECTED:
      return {
        ...state,
        loading: false
      };

    case ACTION_TYPES.FETCH_WRITER_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.BOOKS_SORT:
      const booksOrder = action.sortBy;
      return {
        ...state,
        books: sortBooks(state.books, BOOK_ORDER[booksOrder].sorter),
        booksOrder
      };
    case ACTION_TYPES.FETCH_WRITER_FULFILLED:
      return {
        ...state,
        loading: false,
        uuid: action.uuid,
        name: action.name,
        email: action.email,
        about: action.about,
        books: sortBooks(action.books, BOOK_ORDER[state.booksOrder].sorter),
        photo: action.photo
      };
    case ACTION_TYPES.FETCH_WRITER_REJECTED:
      return {
        ...state,
        loading: false
      };
    case ACTION_TYPES.UPDATE_JOB:
      return {
        ...state,
        jobs: state.jobs.map((job, index) => {
          if (index !== action.index) return job;
          return {
            ...job,
            ...action.job
          }
        })
      }
    case ACTION_TYPES.REMOVE_JOB:
      return {
        ...state,
        jobs: state.jobs.filter((job, i) => i !== action.index)
      }

    case BOOKS_ACTIONS.DELETE_BOOK_PENDING:
    case BOOKS_ACTIONS.DELETE_BOOK_FULFILLED:
    case BOOKS_ACTIONS.DELETE_BOOK_REJECTED:
      return deleteBook(state, action);

    case BOOKS_ACTIONS.IMPORT_EPUB_PENDING:
      return {
        ...state,
        jobs: [action.job, ...state.jobs]
      }
    case BOOKS_ACTIONS.IMPORT_EPUB_FULFILLED:
      return {
        ...state,
        jobs: (function () {
          const pjIdx = action.pendingJobId && state.jobs.findIndex(j => j.id === action.pendingJobId);
          if (pjIdx >= 0) {
            return state.jobs.map((job, i) => {
              if (i !== pjIdx) return job;
              else return action.job;
            })
          } else {
            return [...state.jobs, action.job]
          }
        })()
      }
    default:
      return state;
  }
}

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, InlineNotification, Loading } from 'carbon-components-react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import cn from 'classnames';
import classes from './profile-page.module.scss';
import cs from './ProfilePage.module.scss';
import common from 'src/layout/form/common.module.scss';
import ContentLayout from 'src/layout/ContentLayout';
import InputText from 'src/components/_shared/form/InputText';
import InputArea from 'src/components/_shared/form/InputArea';
import { updateProfile } from 'src/modules/actions/authentication.actions';
import { fetchProfile, clearProfileData } from 'src/modules/actions/profile.actions';
import InputImage from 'src/components/_shared/form/InputImage';
import { RES_PATH } from 'src/config/environment';

const required = value => (value ? undefined : 'Required');

export const ProfilePage = ({ match, loading, account, profile, ...actions }) => {

  let submitTrigger = null;

  const fetchAction = actions.fetchProfile;
  const clearAction = actions.clearProfileData;
  useEffect(() => {
    fetchAction();
    return () => { clearAction(); }
  }, [fetchAction, clearAction]);

  return (
    <>
      <Loading active={loading} />
      <ContentLayout
        title="Profile"
        renderAction={() => <Button disabled={loading} onClick={() => submitTrigger()}>
          Save
          <Loading small={true} withOverlay={false} active={loading}/>
        </Button>}
      >
        { !loading && profile &&
          <Form
            initialValues={{ email: profile.email, name: profile.name, about: profile.about }}
            onSubmit={ (values) => actions.updateProfile(profile.uuid, values, profile.photo, match.path) }
            render={({ handleSubmit, submitting, submitError, pristine, values }) => {
              submitTrigger = handleSubmit;
              const disable = submitting || loading;
              return (
                <>
                  <form onSubmit={handleSubmit} className={cs.container}>

                    <div className={cn('bx--grid', cs.half, cs.avatarContainer)}>
                      <Field
                        id='photo'
                        name='photo'
                        component={InputImage}
                        disabled={disable}
                        initialSrc={profile && profile.photo && RES_PATH + profile.photo}
                        width={100}
                        height={100}
                        scaledWidth={200}
                        scaledHeight={200}
                        imagePlaceholder='user.svg'
                        saveOriginalProportion
                      />
                    </div>


                    <div className={cn('bx--grid', cs.half)}>
                      <div className='bx--row'>
                        <div
                          className={cn(
                            'bx--col-lg-4',
                            'bx--col-md-2',
                            common.fieldTitle
                          )}
                        >
                          Name*
                        </div>
                        <div
                          className={cn(
                            'bx--col-lg-12',
                            'bx--col-md-6'
                          )}
                        >
                          <Field
                            id='name'
                            name='name'
                            component={InputText}
                            validate={required}
                            disabled={disable}
                          />
                        </div>
                      </div>

                      { (account.writer || match.path.endsWith('become')) &&
                        <div className='bx--row'>
                          <div
                            className={cn(
                              'bx--col-lg-4',
                              'bx--col-md-2',
                              common.fieldTitle
                            )}
                          >
                            About*
                          </div>
                          <div className={cn(
                            'bx--col-lg-12',
                            'bx--col-md-6'
                          )}
                          >
                            <Field
                              id='about'
                              name='about'
                              component={InputArea}
                              validate={required}
                              disabled={disable}
                            />
                          </div>
                        </div>
                      }


                      <div
                        className={cn('' +
                          'bx--row',
                          classes.changeContainer
                        )}
                      >
                        <div
                          className={cn(
                            'bx--col-lg-4',
                            'bx--col-md-2',
                            common.fieldTitle
                          )}
                        >
                          Email*
                        </div>
                        <div
                          className={cn(
                            'bx--col-lg-12',
                            'bx--col-md-6'
                          )}
                        >
                          <Field
                            name='email'
                            component={InputText}
                            validate={required}
                            disabled={disable}
                          />
                        </div>
                      </div>
                    </div>

                  </form>

                  {submitError &&
                  <div>
                    <InlineNotification kind='error' lowContrast title="Error" subtitle={submitError} />
                  </div>
                  }
                </>
              )
            }}
          />
        }
      </ContentLayout>
    </>
  )
};

ProfilePage.propTypes = {
  loading: PropTypes.bool.isRequired,
  account: PropTypes.shape({
    writer: PropTypes.bool
  }).isRequired,
  updateProfile: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired
  }).isRequired,
  profile: PropTypes.shape({
    email: PropTypes.string.isRequired,
    about: PropTypes.string,
    name: PropTypes.string,
    photo: PropTypes.string
  }),
  fetchProfile: PropTypes.func.isRequired,
  clearProfileData: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    loading: state.profile.loading,
    avatar: state.profile.avatar, // ???
    account: state.authentication.account,
    profile: state.profile.data
  }
}

export default connect(mapStateToProps, { updateProfile, fetchProfile, clearProfileData })(ProfilePage);

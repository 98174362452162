import React, { useCallback, useEffect, useState } from 'react';
import { Button, Loading, Modal, Breadcrumb, BreadcrumbItem } from 'carbon-components-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import history from 'src/history';
import classes from './index.module.scss';
import Layout from 'src/layout/ContentLayout';
import ChapterItem from './ChapterItem';
import { fetchBook, forgetBook, toggleChapterPublished } from 'src/modules/actions/books.actions';
import * as coupons from 'src/modules/api/coupons.api';
import { deleteChapter } from 'src/modules/actions/chapters.actions';
import BookPortrait from 'src/components/_shared/Book/BookPortrait';
import BookDescription from 'src/components/_shared/Book/BookDescription';
import ModalForm from 'src/components/_shared/form/ModalForm';
import ShareBanner from 'src/components/_shared/ShareBanner';
import Delimiter from 'src/components/_shared/Delimiter';
import { LinkItem } from './LinkItem';

const Crumbs = () => (
  <Breadcrumb>
    <BreadcrumbItem><Link to='/my-books'>My Books</Link></BreadcrumbItem>
  </Breadcrumb>
);

export const BookPage = ({ match, book, loading, fetchBook, forgetBook, deleteChapter, toggleChapterPublished }) => {

  const ActionButton = () => (
    <div className={classes.actionButtons}>
      <Button
        kind='ghost'
        disabled={loading}
        onClick={() => history.push(`${match.url}/edit`)}
      >
        Edit
      </Button>
        <Button className={classes.publicView} onClick={() => history.push(`/books/${book.uuid}`)}>
        { book?.published ? "Public View" : "Preview" }
        </Button>
    </div>
  );
  const { name = '', about = '', chapters = [] } = book || {};

  useEffect(() => {
    fetchBook(match.params.id);
    return forgetBook;
  }, [match, forgetBook, fetchBook]);

  const [deletingChapter, setDeletingChapter] = useState(null);
  const [sharingChapter, setSharingChapter] = useState(null);
  const [chapterCoupons, setChapterCoupons] = useState([]);

  let submitTrigger = null;

  const CouponsList = useCallback(() => {
    console.log('chapterCoupons: ', JSON.stringify(chapterCoupons));
    return (chapterCoupons.map && chapterCoupons.map((coupon)=> 
    <LinkItem 
      href={ link(coupon.shortId) }
      expiration= {coupon.expiration}
      onDelete={() => {
        coupons.deleteCoupon(coupon.uuid).then(() => {
          coupons.getChapterCoupons(sharingChapter[0].uuid).then((response) => {
            setChapterCoupons(response.data.items)
          })
        })
      }}
    />));
  }, [chapterCoupons, sharingChapter])

  return (<>
    <Layout
      title={name}
      renderAction={ActionButton}
      breadcrumb={<Crumbs />}
    >
      <Loading
        active={loading}
        withOverlay
      />

      <div className={classes.bookPage}>

        <BookPortrait book={book} showLikes={false}/>
        <BookDescription>{about}</BookDescription>
        {book?.published && <ShareBanner book={book}/>}
        <Delimiter style={{ marginTop: '1rem', marginBottom: '1rem' }}/>
        <div className={classes.addChapterButton}>
          <Button
            kind="tertiary"
            onClick={() => history.push(`${match.url}/chapter/add`)}
          >Add Chapter</Button>
        </div>

        {
          chapters.map((chapter, i) => (
            <ChapterItem
              key={i}
              chapter={chapter}
              onDeleteChapter={() => setDeletingChapter([chapter, i])}
              onTogglePublished={() => toggleChapterPublished(chapter.uuid, i, !chapter.published)}
              onAddChapterAbove={() => history.push(`${match.url}/chapter/add?before=${chapter.uuid}`)}
              onSharableLink={() => {
                coupons.getChapterCoupons(chapter.uuid).then((response) => {
                  setChapterCoupons(response.data.items)
                  setSharingChapter([chapter, i])
                })
              }}
              onPreview={() => history.push(`/books/${book.uuid}/${chapter.uuid}`)}
              match={match}
            />
          ))
        }
      </div>
    </Layout>
    <Modal
      open={deletingChapter}
      danger
      shouldSubmitOnEnter
      modalHeading={deletingChapter && deletingChapter[0].name}
      modalLabel="Delete chapter"
      aria-label='delete-chapter'
      primaryButtonText="Yes"
      secondaryButtonText="No"
      onRequestClose={() => setDeletingChapter(null)}
      onRequestSubmit={() => {
        deleteChapter(deletingChapter[0].uuid, deletingChapter[1]);
        setDeletingChapter(null);
      }}
    >
      <p className={`bx--modal-content__text`}>
        Are you sure that you really want to delete this chapter?
      </p>
    </Modal>
    <ModalForm
      modalHeading = "Create Sharable Chapter Link"
      open = {!!sharingChapter}
      setTrigger = {(trigger) => submitTrigger = trigger}
      properties={[
        {
          type: "custom", 
          custom : {
            component: CouponsList()
          }
        },
        { title: "Expiration", type: "date", id: "expiration", default: Date.now()/1000 + 60*60*24+7},
        {
          type: "custom", 
          custom : {
            component: (<Button onMouseUp={()=>submitTrigger()}>Create</Button>)
          }
        }
      ]}
      passive
      cancel={()=> setSharingChapter(null)}
      submit={(value) => {
        coupons.postChapterCoupon(sharingChapter[0].uuid, value.expiration).then((response) => {
          coupons.getChapterCoupons(sharingChapter[0].uuid).then((response) => {
            setChapterCoupons(response.data.items)
          })
        }).catch( error => {
        })
      }}
    ></ModalForm>
  </>)
};

const link = (code) => {
  // This link is parsed by renderingg server and redirected to the end page.
  return `${window.location.origin}/l/c/${code}`
}

export default connect(
  state => ({
    book: state.books.book,
    loading: state.books.loading
  }),
  dispatch => bindActionCreators({ fetchBook, forgetBook, deleteChapter, toggleChapterPublished }, dispatch))(BookPage);

import { useCallback, useEffect, useRef, useState } from 'react';
import { fetchBook as fetchBookApi } from 'src/modules/api/books.api';
import * as cookiesApi from 'src/modules/api/cookie.api';
import { getAuthHeader } from 'src/modules/api/utils';
import { API_URL } from 'src/config/environment';
import axios from 'axios';
import * as utils from 'src/modules/api/utils';
import history from 'src/history';
import { isChapterAvailable } from 'src/modules/model/book';
import { isAuthenticated } from 'src/modules/api/cookie.api';
import ReactGA from 'react-ga';

let globalContext = [];

class Player {
  PERIOD = 3000;
  audio = null;
  bookId = null;
  chapterId = null;
  handler = null;
  paused = false;
  playPromise = null;
  currentTime = null;
  duration = 0;

  playingHandler = null;
  t0 = null;

  sendProgress() {
    const seek = this.seek();
    const duration = this.getDuration();
    const percent = seek / duration;
    try {
      const params = {progress: percent, chapter: this.chapterId };
      axios.put(`/books/${this.bookId}/progress`, params, utils.getAuthHeader());
    } catch (e) {
      console.log(e);
    }
    this.handler = setTimeout(this.sendProgress.bind(this), this.PERIOD);
  }

  clearHandler() {
    if (this.handler) {
      clearTimeout(this.handler);
    }
  }

  constructor(options) {
    this.duration = options.duration;
    this.bookId = options.bookId;
    this.chapterId = options.chapterId;
    this.audio = new Audio(options.src);
    this.audio.autoplay = options.autoplay;
    this.audio.preload = options.preload;
    this.audio.onplay = () => {
      options.onplay();
      this.clearHandler();
      this.paused = false;
    };
    this.audio.onpause = () => {
      options.onpause();
      this.clearHandler();
    }
    this.audio.onabort = () => {
      this.clearHandler();
    }
    this.audio.onwaiting = () => {
      this.clearHandler();
    }
    this.cancelBuffering = options.onplaying;
    this.audio.onplaying = () => {
      this.handler = setTimeout(this.sendProgress.bind(this), this.PERIOD);
      this.audio.volume = 0;
      this.playing();
    }
    this.audio.onseeking = options.seeking;
    this.audio.onseeked = options.seeked;
  }

  playing() {
    if (!this.t0) {
      this.t0 = this.seek();
      this.playingHandler = setTimeout(this.playing.bind(this), 100);
    } else if (Math.abs(this.t0 - this.seek()) > 0.5) {
      this.cancelBuffering();
      if (this.currentTime) {
        setTimeout(this.pos.bind(this, this.currentTime), 10);
        this.currentTime = null;
      } else {
        this.audio.volume = 1;
      }
    } else {
      this.playingHandler = setTimeout(this.playing.bind(this), 100);
    }
  }

  play() {
    this.audio.play().then(_ => {
      this.pause = () => {
        this.paused = true;
        this.audio.pause();
      }
    }).catch(error => {
      this.pause = () => {
        this.paused = true;
        this.audio.pause();
      }
    })
  }

  pause() {
    // this.paused = true;
    // return this.audio.pause();
  }

  stop() {
    if (this.audio) {
      this.audio.pause();
      this.audio.autoplay = false;
      this.audio.arc = 'data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA';
      this.audio.load();
    }
  }

  seek() {
    return this.audio.currentTime;
  }

  pos(sec) {
    this.audio.currentTime = sec;
    this.audio.volume = 1;
  }

  rewind30() {
    this.pos(this.seek() - 30);
  }

  forward30() {
    this.pos(this.seek() + 30);
  }

  getDuration() {
    return this.duration > 0 ? this.duration : this.audio.duration;
  }

  static stop() {
    globalContext.forEach(p => p.stop());
    globalContext = [];
  }
}


const MP3_GEN_STATUSES = {
  COMPLETED: 'succeeded',
  NOT_STARTED: 'waiting',
  IN_PROGRESS: 'inProgress',
  FAILED: 'failed'
}

async function checkStatus(chapterUuid) {
  const res = await axios.get(`/chapters/${chapterUuid}/mp3/state`, getAuthHeader());
  if (res.status !== 200) throw new Error('cannot get status');
  return res.data;
}

async function generate(chapterUuid) {
  return await axios.post((`/chapters/${chapterUuid}/mp3/generate`), {}, getAuthHeader());
}

export default (bookUuid, chapterUuid) => {
  const [externalPlaylist, setExternalPlaylist] = useState([]);
  const playlist = useRef([]);
  const [book, setBook] = useState(null);
  const [bookLoading, setBookLoading] = useState(false);
  const [currentChapter, setCurrentChapter] = useState(chapterUuid);
  const [playing, setPlaying] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [autoplayChapter, setAutoplayChapter] = useState(null);

  const [status, setStatus] = useState();
  const [timerHandler, setTimerHandler] = useState();

  const [buffering, setBuffering] = useState();
  const [buffHandler, setBuffHandler] = useState();
  const [cancelBuffering, setCancelBuffering] = useState(false);

  const fetchBook = useCallback(async () => {
    const res = await fetchBookApi(bookUuid);
    if (res?.status !== 200) throw new Error("cannot fetch book");
    return res.data;
  }, [bookUuid]);

  const reloadBook = useCallback(() => {
    bookUuid && setBookLoading(true);
    bookUuid && fetchBook()
      .then(b => setBook(b))
      .catch(console.log)
      .finally(() => setBookLoading(false));
  }, [bookUuid, fetchBook]);

  useEffect(() => {
    reloadBook();
  }, [reloadBook]);

  useEffect(() => {
    if (book) {
      playlist.current =
        book.chapters.filter(c => c.audio && c.published).map(c => {
          const trk = playlist.current.find(p => p.uuid === c.uuid);
          if (trk) return trk;
          else return ({ ...c, howl: null });
        });
      setExternalPlaylist(playlist.current)
    }
  }, [book]);

  const checkStatusLoop = async (chapter) => {
    const _status = await checkStatus(chapter);
    _status.chapter = chapter;
    setStatus(_status);
  };

  useEffect(() => {
    if (cancelBuffering) {
      if (buffHandler) {
        clearTimeout(buffHandler);
        setBuffHandler(null);
        setBuffering(false);
        setCancelBuffering(false);
      }
    }
  }, [cancelBuffering, buffHandler]);

  const wouldStartPlaying = useCallback(async (chapter, autoplay = false) => {
    let chapterUUID = chapter.uuid
    setDisabled(true);
    if (playlist.current.length > 0) {
      const _playlist = playlist.current;
      const i = _playlist.findIndex(p => p.uuid === chapterUUID);
      if (i >=0) {
        const track = _playlist[i];
        ReactGA.event({ category: 'Listen', action: `${book?.uuid}-${track.uuid}`});
        if (!track.howl) {
          const withToken = isAuthenticated() ? `?AuthToken=${cookiesApi.getAuthToken()}` : '';
          track.howl = new Player({
            src: `${API_URL}/chapters/${track.uuid}/mp3${withToken}`,
            html5: true,
            pool: 1,
            preload: 'metadata',
            onplay: () => {
              track.playing = true;
              track.howl.paused = true;
              setDisabled(false);
              setPlaying(true);
            },
            onpause: () => {
              track.playing = false;
              setPlaying(false);
              track.howl.paused = true;
            },
            bookId: book?.uuid,
            chapterId: track.uuid,
            duration: track.audio?.duration,
            autoplay,
            onplaying: () => setCancelBuffering(true),
            seeking: () => setBuffHandler(setTimeout(() => { setBuffering(true); }, 0)),
            seeked: () => setCancelBuffering(true)
          });
          globalContext.push(track.howl);
          track.seek = 0;
          track.playing = false;
        }

        const pi = _playlist.findIndex(t => t.playing && t.uuid !== chapterUUID);
        if (pi >= 0) {
          _playlist[pi].playing = false;
          _playlist[pi].howl.stop();
          _playlist[pi].howl = null;
          setDisabled(false);
          setPlaying(false);
        }

        if (track.playing) {
          track.playing = false;
          track.howl.pause();
          setPlaying(false);
          setDisabled(false);
        } else {
          if (isChapterAvailable(book, track)) {
            track.playing = true;
            if (!track.howl.paused && track.uuid === book?.progress?.chapter) {
              track.howl.currentTime = (book.progress.progress ?? 0) * track.audio.duration;
            }
            if (!autoplay) {
              track.howl.play();
            }
            setBuffHandler(setTimeout(() => {
              setBuffering(true);
            }, 1000));
            setPlaying(true);
            setDisabled(true);
          } else {
            setDisabled(false);
          }
        }


        setCurrentChapter(chapterUUID);
        setExternalPlaylist([...playlist.current]);
      } else {
        Player.stop();
        setPlaying(false);
        setCurrentChapter(null);
        setDisabled(false);
        await checkStatusLoop(chapterUUID);
      }
    } else {
      generate(chapterUUID)
      //setStatus(MP3_GEN_STATUSES.IN_PROGRESS)
      // Player.stop();
      // setPlaying(false);
      // setCurrentChapter(null);
      // setDisabled(false);
      // await checkStatusLoop(chapterUUID);
  }
  }, [playlist, book]);

  useEffect(() => {
    if (autoplayChapter && playlist?.current?.length > 0) {
      const id = autoplayChapter;
      setAutoplayChapter(null);
      wouldStartPlaying(id, true);
      history.replace(`/listen/${bookUuid}`);
    }
  }, [wouldStartPlaying, playlist, autoplayChapter, bookUuid]);

  useEffect(() => {
    if (status?.state === MP3_GEN_STATUSES.COMPLETED) {
      setStatus(null);
      wouldStartPlaying(status.chapter);
    }
  }, [playlist, wouldStartPlaying, status]);

  useEffect(() => {
    if (status) {
      switch (status.state) {
        case MP3_GEN_STATUSES.COMPLETED:
          reloadBook();
          break;
        case MP3_GEN_STATUSES.NOT_STARTED:
          generate(status.chapter).then(() => {
            setTimerHandler(setTimeout(() => checkStatusLoop(status.chapter), 1000));
          });
          break;
        case MP3_GEN_STATUSES.IN_PROGRESS:
          setTimerHandler(setTimeout(() => checkStatusLoop(status.chapter), 1000));
          break;
        case MP3_GEN_STATUSES.FAILED:
          generate(status.chapter).then(() => {
            setTimerHandler(setTimeout(() => checkStatusLoop(status.chapter), 1000));
          });
          break;
        default:
          break;
      }
    }
  }, [status, wouldStartPlaying, reloadBook]);

  const cancelCheckingStatus = useCallback(() => {
    if (timerHandler) {
      clearTimeout(timerHandler);
      setTimerHandler(null);
      setStatus(null);
    }
  }, [timerHandler]);

  useEffect(() => {
    return () => {
      Player.stop();
    }
  }, []);

  return {
    playlist: externalPlaylist,
    bookLoading,
    wouldStartPlaying,
    currentChapter,
    book,
    playing,
    cancelCheckingStatus,
    status,
    disabled,
    buffering,
    setCancelBuffering
  }
}

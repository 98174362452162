import React from 'react';
import { Button } from 'carbon-components-react';
import { CopyFile24, Delete24 } from '@carbon/icons-react';

export const LinkItem = ({ href, expiration, onDelete }) => {
    const date = new Date(expiration * 1000)
    const dateString = `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;

  return (<div style={{lineHeight: "32px", width: "100%"}}>
        <div style={{width:"calc(100% - 116px)", display:"inline-block", overflowWrap: "anywhere"}}>{href}, {dateString}</div>
        <Button size='small'
            onClick={() => navigator.clipboard.writeText(href)}
            kind='ghost'
        >
              <CopyFile24 />
        </Button>
        <Button size='small'
            onClick={() => onDelete() }
            kind='ghost'
        >
              <Delete24 />
        </Button>
   </div>)
};
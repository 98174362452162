import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useResizeEffect } from 'src/hooks'
import PropTypes from 'prop-types';
import { InlineNotification } from 'carbon-components-react';
import { Loading } from 'carbon-components-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import cs from './styles.module.scss';
import cn from 'classnames';
import { useQuery } from 'src/hooks';
import { fetchBook, forgetBook } from 'src/modules/actions/books.actions';
import { redirectToCheckout, closeError } from 'src/modules/actions/payment.actions';
import BookPortrait from 'src/components/_shared/Book/BookPortrait';
import BookDescription from 'src/components/_shared/Book/BookDescription';
import ChapterItem from 'src/components/reader/BookPage/ChapterItem';
import ReadButton from 'src/components/reader/BookPage/ReadButton';
import ListenButton from 'src/components/_shared/ListenButton';
import BuyButton, { canBuy } from 'src/components/_shared/BuyButton';
import { useBookTitle } from 'src/hooks';
import { RES_PATH } from 'src/config/environment';
import { Helmet } from "react-helmet";
import Comments from 'src/components/reader/BookPage/Comments';
import Delimiter from 'src/components/_shared/Delimiter';
import { usePurchaseGA } from 'src/components/reader/BookPage/usePurchaseGA';
import Like from 'src/components/_shared/Like';
import Bookmark from 'src/components/_shared/Book/Bookmark';
import HorizontalItemList from 'src/components/_shared/DesignV2/HorizontalItemList';
import history from 'src/history';

let [x, y] = [0, 0];
const onMouseDown = e => { x = e.clientX; y = e.clientY; };
const onMouseUp = cb => e => {
  if (e.clientX === x && e.clientY === y) cb(e);
};

const BookPage = ({ match, book, loading, error, puchased, ...actions }) => {
  const { fetchBook, forgetBook } = actions;
  usePurchaseGA(book);
  useBookTitle(book);
  useEffect(() => {
    fetchBook(match.params.bookUuid);
    return forgetBook;
  }, [fetchBook, forgetBook, puchased, match]);

  const query = useQuery();
  const session = query.get('session');
  const toastKind = (error || (session && session !== 'success')) ? 'error' : 'success';
  const toastTitle = error || `Payment ${toastKind}`;
  const toast = error || session;
  const [gridWidth, setGridWidth] = useState(500);
  const [showLoading, setShowLoading] = useState(true);
  const [startTime, setStartTime] = useState(undefined);
  const containerRef = useRef(null);

  const resizeListener = useCallback(() => {
    if (containerRef?.current) {
      setGridWidth(containerRef.current.offsetWidth);
    }
  }, [containerRef]);

  useResizeEffect(resizeListener);

  useEffect(() => {
    resizeListener();
  }, [containerRef, resizeListener]);

  console.log("reloaded");

  useEffect(() => {
    if(book?.pricing.state === "coinbasePending") {
      setShowLoading(false);
      if(startTime ===  undefined) { setStartTime(Date.now()) }
      if(Date.now() - startTime > 420000/*7 minutes*/) { return; }

      const timer = setTimeout(() => { fetchBook(match.params.bookUuid) }, 5000);
      return () => clearTimeout(timer);
    }
  }, [book, fetchBook, match.params.bookUuid]);

  const name = book?.name;
  const author = book?.author?.name;
  const about = book?.about;
  const image = book?.image ? `${RES_PATH}${book.image}` : null;
  return (
    <>
      <Helmet>
        {name && author && <meta property="og:title" content={`Fictionate.me: ${name} by ${author}`} />}
        {about && <meta property="og:description" content={about} />}
        {image && <meta property="og:image" content={image} />}
      </Helmet>
      { toast &&
        <InlineNotification kind={toastKind}
                            title={toastTitle}
                            className={cs.toast}
                            onCloseButtonClick={actions.closeError}
                            timeout={5000}
                            lowContrast={true}
        />
      }
      {loading && showLoading && <div className={cs.loading}><Loading withOverlay={false} small/></div>}
      {((!loading || !showLoading) && book) &&
        <div className={cs.container}>
          <div className={cs.content} ref={containerRef}>
            <BookHeader book={book}/>
            {book?.bookSeries?.books &&
              (<>
                <div className={cs.outlines}>Book Series: {book.bookSeries.name}</div>
                <Delimiter />
                <HorizontalItemList items={book.bookSeries.books}
                                            width={(122)}
                                            height={150}
                                            screenWidth={gridWidth}
                                            >
                                              {(item) => (
                                                  <BookItem item={item} selected={item.uuid === book.uuid} />
                                              )}
                </HorizontalItemList>
              </>)
            }
            <div  style={{marginBottom: "18px"}} />
            <BookDescription>{book.about}</BookDescription>
            <div className={cs.outlines}>Chapters</div>
            <Delimiter />
            {
              book.chapters
                .filter(chapter => chapter.published)
                .map((chapter, index) => (
                <ChapterItem key={index}
                            book={book}
                            buyClicked={() => actions.redirectToCheckout(book, chapter)}
                            {...chapter}
                />
              ))
            }

            <Comments bookUuid={book.uuid}/>
            <div className={cs.bottomBuy}>
            {canBuy(book) &&
              <BuyButton style={{ minWidth: "200px"}} book={book} />
            }
            </div>

          </div>
        </div>
      }
    </>
  )
};

const BookHeader = ({ book }) => {
  return (
    <BookPortrait book={book}>
    <div className={cs.buttons}>
      <div className={cs.buttonsContainer}>
        <div className={cs.actionsWrapper} >  
          <Bookmark className={cs.actionItem} uuid={book?.uuid} style={{ padding: 0 }}/>
          <Like style={{marginLeft: "18px"}} className={cs.actionItem} {...book} size='m' showLikes={true} likesFirst={false} enableSharing/>
        </div>
        <div className={cs.buttonsWrapper}>
          <ReadButton book={book} className={cs.button} />
          {book?.narration && <ListenButton book={book} className={cs.button} />}
        </div>
        {canBuy(book) &&
          <div className={cs.buyBlock}>
            <BuyButton book={book} className={cs.buyButton} showOriginalPrice/>
          </div>
        }
      </div>
    </div>
  </BookPortrait>);  
};

const BookItem = ({ item, selected }) => {
  const bookURL = () => {
    return `/books/${item.uuid}`;
  };

  const goBook = (e) => {
    e.stopPropagation();
    history.push(bookURL());
  };
    
  return (
    <div className={cs.bookCell}
         onMouseDown={onMouseDown}
         onMouseUp={onMouseUp(goBook)}>
      <div className={cn(cs.imageContainer, {[cs.selected]: selected})} style={{ backgroundImage: `url(${RES_PATH}${item.image})` }}/>
    </div>)
};

BookPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      bookUuid: PropTypes.string.isRequired
    })
  }),
  book: PropTypes.shape({
    name: PropTypes.string.isRequired,
    about: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    tags: PropTypes.array,
    narration: PropTypes.bool,
    chapters: PropTypes.arrayOf(PropTypes.shape({
      uuid: PropTypes.string,
      published: PropTypes.bool,
      name: PropTypes.string,
      filePath: PropTypes.string,
      fileType: PropTypes.string,
      free: PropTypes.bool,
      charactersCount: PropTypes.number,
      readCount: PropTypes.number
    })),
    author: PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  }),
  fetchBook: PropTypes.func.isRequired,
  forgetBook: PropTypes.func.isRequired,
  closeError: PropTypes.func.isRequired,
  redirectToCheckout: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.any
};

function mapStateToProps(state) {
  return {
    book: state.books.book,
    loading: state.books.loading,
    error: state.payment.error,
    puchased: state.payment.purchased
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchBook, forgetBook, closeError, redirectToCheckout }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BookPage);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Select, SelectItem } from 'carbon-components-react';
import { Helmet } from 'react-helmet';

import cs from './styles.module.scss';
import { fetchAuthor, sortBooks } from 'src/modules/actions/writers.actions';
import { BOOK_ORDER } from 'src/modules/reducers/writers.reducer';
import { RES_PATH } from 'src/config/environment';
import AppLayout from 'src/layout/AppLayout';
import AuthorWide from 'src/components/_shared/AuthorWide';
import Delimiter from 'src/components/_shared/Delimiter';
import PublicBookCard from  'src/components/_shared/PublicBookCard';
import { useAuthorTitle } from 'src/hooks';

export const AuthorPage = props => {
  const { match, name, books, about, photo, ...actions } = props;
  const { fetchAuthor } = actions;

  useAuthorTitle(name);
  useEffect(() => {
    fetchAuthor(match.params.uuid);
  }, [fetchAuthor, match]);

  const onSort = (e) => actions.sortBooks(e.target.value);

  return (
    <>
      <Helmet>
        {name && <meta property="og:title" content={`Fictionate.me: Novels by ${name}`} />}
        {about && <meta property="og:description" content={about} />}
        {photo && <meta property="og:image" content={`${RES_PATH}${photo}`} />}
      </Helmet>
      <AppLayout>
        <div className={cs.content}>
          <AuthorWide {...props} />
          <Delimiter />

          <div className={cs.books}>
            <div>Books</div>
            <div>
              <Select
                className={cs.sortBy}
                id='select-1'
                labelText='Sort by'
                onChange={onSort}
              >
                {
                  Object.getOwnPropertyNames(BOOK_ORDER).map((O,i) => (
                    <SelectItem key={i} value={O} text={BOOK_ORDER[O].name} />
                  ))
                }
              </Select>
            </div>
          </div>

          <div className='bx--grid--full-width'>
            <div className='bx--row' style={{ justifyContent: 'space-between'}}>
            {
              books.map((item, index) => (
                <div style={{ maxHeight: '380px', width: 246, marginBottom: '1rem' }} key={index}>
                  <PublicBookCard key={index} book={item.book} className={cs.bookCard} />
                </div>
              ))
            }
            </div>
          </div>

        </div>
      </AppLayout>
    </>
  )
};

AuthorPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,

  fetchAuthor: PropTypes.func.isRequired,
  sortBooks: PropTypes.func.isRequired,
  name: PropTypes.string,
  about: PropTypes.string,
  photo: PropTypes.string
};

function mapStateToProps(state) {
  return {
    ...state.writers
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchAuthor, sortBooks }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorPage);

import history from 'src/history';
import ReactGA from 'react-ga';
import { FORM_ERROR } from 'final-form';

import * as writersApi from 'src/modules/api/writers.api';
import * as booksApi from 'src/modules/api/books.api';
import { ACTION_TYPES } from 'src/modules/reducers/writers.reducer';
import JobUpload from 'src/modules/model/job-upload';

export function submitBookForm(values) {
  return async dispatch => {

    const dispatchError = message => {
      dispatch({ type: ACTION_TYPES.CREATE_BOOK_REJECTED });
      return { [FORM_ERROR]: message };
    };

    const makeBookData = async ({ uuid, name, about, priceTier, published, rating, tags, imageFile: imageData, narration, customStyles, bookSeries, bookSeriesIndex}) => {
      const data = {
        name,
        about,
        published,
        rating,
        tags: [],
        priceTier: priceTier,
        narration,
        customStyles,
        bookSeries,
        bookSeriesIndex: parseInt(bookSeriesIndex)
      };
      if (uuid) {
        data.uuid = uuid;
      }
      if (rating) {
        data.rating = rating;
      }
      if (tags) {
        data.tags = tags;
      }
      if (imageData) {
        data.image = {
          data: imageData.split(',')[1],
          type: 'jpg'
        }
      }
      return data;
    };

    dispatch({ type: ACTION_TYPES.CREATE_BOOK_PENDING });

    let uuid = values.uuid;
    const book = await makeBookData(values);

    // editing
    if (uuid) {
      const response = await booksApi.putBook(book);
      if (response.status !== 200) {
        return dispatchError("Book hasn't been saved.");
      }

    // creating
    } else {
      // require an image
      if (!values.imageFile) {
        return dispatchError("You must upload an image.");
      }

      const response = await writersApi.postBook(book);
      if (response.status === 200) {
        uuid = response.data.uuid;

        ReactGA.event({
          category: 'writer',
          action: 'add_book'
        });
      } else {
        return dispatchError("Book hasn't been saved.");
      }
    }

    dispatch({ type: ACTION_TYPES.CREATE_BOOK_FULFILLED });
    history.push(`/my-books/${uuid}`);

  }
}

export function fetchBooks() {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.FETCH_BOOKS_PENDING });

    const response = await writersApi.getBooks();

    if (response.status !== 200) {
      dispatch({ type: ACTION_TYPES.FETCH_BOOKS_REJECTED });
    } else {
      const data = response.data;
      const books = data.books;
      const jobs = data.jobs.filter(job => job.state === 'inProgress');
      dispatch({ type: ACTION_TYPES.FETCH_BOOKS_FULFILLED, books, jobs });
    }
  }
}

export function fetchAuthor(uuid) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.FETCH_WRITER_PENDING });

    const response = await writersApi.getWriter(uuid);

    if (response.status !== 200) {
      dispatch({ type: ACTION_TYPES.FETCH_WRITER_REJECTED });
    } else {
      const data = response.data;
      dispatch({ type: ACTION_TYPES.FETCH_WRITER_FULFILLED, ...data });
    }
  }
}

export function sortBooks(sortBy) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.BOOKS_SORT, sortBy });
  }
}

export function checkJob(id) {
  return async (dispatch, getState) => {
    const res = await writersApi.getJobStatus(id);
    const jobs = getState().writers.jobs;
    const index = jobs.findIndex(j => j.id === id);
    if (index >= 0) {
      const curJob = jobs[index];

      switch (res.data.state) {
        case JobUpload.state.IN_PROGRESS:
          dispatch({ type: ACTION_TYPES.UPDATE_JOB, index, job: { ...curJob, ...res.data } });
          break;
        case JobUpload.state.COMPLETED:
          dispatch(fetchBooks()); // eslint-disable-next-line no-fallthrough
        case JobUpload.state.FAILED:
        default:
          dispatch({ type: ACTION_TYPES.REMOVE_JOB, index })
      }
    }
  }
}

export function updateJobProgress(id, progress) {
  return (dispatch, getState) => {
    const jobs = getState().writers.jobs;
    const index = jobs.findIndex(j => j.id === id);
    if (index >= 0) {
      const curJob = jobs[index];
      dispatch({ type: ACTION_TYPES.UPDATE_JOB, index, job: { ...curJob, progress } });
    }
  }
}

import React from 'react';

import cs from './styles.module.scss';
import { RES_PATH } from 'src/config/environment';
import PropTypes from "prop-types";
import history from 'src/history';
import cn from 'classnames';

export const AuthorWide = ({ uuid, loading, name, photo, imagePath, about, books, clickable }) => {

  const onClick = () => clickable ? history.push('/writers/' + uuid) : null;
  return (
    <>
      { name && !loading &&
        <div className={cn([cs.main], { [cs.clickable]: clickable })}
             onClick={onClick}
        >

          <div className={cs.author}>
            <div className={cs.imgArea}>
              <div
                className={cs.avatar}
                style={{
                  backgroundImage: `url('${RES_PATH}${photo || imagePath}')`,
                  backgroundSize: 'cover'
                }}/>
            </div>

            <div className={cs.nameArea}>
              <div className={cs.name}>
                <h3 className={cs.text}>{name}</h3>
              </div>
              <div className={cs.descr}>{about}</div>
              <div className={cs.counters}>
                {books && <div className={cs.fb50}>{books.length}&nbsp; {books.length > 1 ? 'books' : 'book'}</div>}
                <div className={cs.fb50} />
              </div>
            </div>
          </div>

          <div className={cs.toolbar} />

        </div>
      }
    </>
  )
};

AuthorWide.defaultProps = {
  clickable: false
};

AuthorWide.propTypes = {

  uuid: PropTypes.string,
  name: PropTypes.string,
  photo: PropTypes.string,
  imagePath: PropTypes.string,
  about: PropTypes.string,
  followers: PropTypes.number,
  books: PropTypes.array,
  clickable: PropTypes.bool
};

export default AuthorWide;

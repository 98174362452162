import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunk from 'redux-thunk';

// Logger with default options
import logger from "redux-logger";

import authentication from 'src/modules/reducers/authentication.reducer';
import application from 'src/modules/reducers/application.reducer';
import writers from 'src/modules/reducers/writers.reducer';
import books from 'src/modules/reducers/books.reducer';
import chapters from 'src/modules/reducers/chapters.reducer';
import profile from 'src/modules/reducers/profile.reducer';
import home from 'src/modules/reducers/home.reducer';
import genres from 'src/modules/reducers/genres.reducer';
import me from 'src/modules/reducers/me.reducer';
import modal from 'src/modules/reducers/modal.reducer';
import payment from 'src/modules/reducers/payment.reducer';
import search from 'src/modules/reducers/search.reducer';
import coupon from 'src/modules/reducers/coupon.reducer';
import tags from 'src/modules/reducers/tags.reducer';
import blogPost from 'src/modules/reducers/blogPost.reducer';
import blog from 'src/modules/reducers/blog.reducer';
import theme from 'src/modules/reducers/theme.reducer';
import personas from 'src/modules/reducers/personas.reducer';
import bgsounds from 'src/modules/reducers/bgsounds.reducer';

const rootReducer = combineReducers({ authentication, application, writers, books, chapters, profile, home, genres, me, modal, payment, search, coupon, tags, blog, blogPost, theme, personas, bgsounds });
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function configureStore(initialState) {
  const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(logger, thunk)));
  return store;
}

export default configureStore({});

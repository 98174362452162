import React from 'react';

import AppLayout from 'src/layout/AppLayout';
import StaticLayout from './static-layout';

import commonStyles from './common.module.scss';

const TermsOfServicePage = () => {
  return (
    <AppLayout>
      <StaticLayout contentClass={commonStyles.staticWrapper}>
        <h1>Terms of Service</h1>
        <h2>Minimum Age Requirement</h2>
Users of Fictionate.me must meet the minimum age requirement of 13 years of age. The account of any user under this age requirement will be terminated upon discovery.
<h2>Registration and Account Creation</h2>
Registration and account creation, including an account name and password, are required to fully access the Site and all Services offered by fictionate.me. The user is responsible for all account activity and protection of account information. Fictionate.me is not liable to settle disputes between users claiming to hold the same account. As such, Fictionate.me is not responsible for the failure of any user to keep their account information confidential, nor any loss of account control thereafter. Suspicion or discovery of unauthorized action on an account may be reported to info@fictionate.me.
<h2>User Content</h2>
It is in the user’s best interest to read fictionate.me's Content Guidelines prior to posting content on the Site. Content not in compliance with the Guidelines may be removed at any time. All rights to content belong to the user who created and posted it.
When posting content, users must either:
<ul>
<li>Hold the copyright for the content.</li>
<li>Have permission to post, and grant to Fictioante.me all rights outlined in these Terms of Service related to, copyrighted content.</li>
<li>Provide Fictioante.me with a nonexclusive license to publish, and any action reasonably related to publication (e.g. storage, display, format) of, that content on Fictioante.me services under applicable copyright laws.</li>
</ul>
Any content posted by a user is the responsibility of that user; that is to say, the user assumes all risks related to content publication and display, including content accuracy and any claims related to intellectual property or other legal rights.
While any user’s content may be used by Fictionate.me to promote Services offered, Fictionate.me must obtain the user’s explicit consent prior to selling the user’s content to third parties. The user may assume additional agreements and terms and conditions for use of Services that change or override these Terms of Service in case of conflict or inconsistency.
<h2>Fictionate.me Rights in Services</h2>
Fictionate.me reserves all rights to the look and feel of the Site, Services, and content. Users are prohibited to copy or adapt any code or visual design elements without express written permission from Fictionate.me, or as set out in these Terms of Service.
Fictionate.me’s logo or trademarks may not be:
<ul>
<li>Used or altered as to suggest that Fictionate.me endorses or has a business relationship with a product, service, or individual.</li>
<li>Altered in any way (e.g. stretched, condensed, embellished, etc.)</li>
</ul>
Fictionate.me may:
<ul>
<li>Change, terminate, or restrict access to Services at any time and without notice.</li>
<li>Remove any content, posted or submitted, for any reason.</li>
<li>Access, read, preserve, and disclose any information reasonably believed necessary to:</li>
<ul>
<li>Satisfy applicable law(s), regulation(s), legal process(es), or governmental request(s).</li>
<li>Enforce Terms of Service, including to investigate potential violations.</li>
<li>Detect, prevent, or otherwise act against fraud, security, or technical issues</li>
<li>Respond to user support requests.</li>
<li>Protect the rights, property, or safety of Services, users of Fictionate.me, and/or the public.</li>
</ul>
</ul>
<h2>Copyright Issues</h2>
While in accordance with the United Stated Digital Millennium Copyright Act (DMCA), Fictionate.me’s copyright infringement policy additionally applies to all jurisdictions in which Services are available, globally. Claims of copyright infringement may be directed to info@fictionate.me. Fictionate.me reserves the right and sole discretion to remove any allegedly infringing content without notice or liability to users. Repeatedly infringing accounts may be terminated.
Upon registration, users must grant Fictionate.me the authority to act on their behalf should Fictionate.me discover any user’s content illegally featured on another website. This authorization allows Fictionate.me to issue take down notices under the DMCA and/or other legislation allowing the submission of request(s) for removal of infringing, or allegedly infringing, copyrighted materials contained in or displayed on platforms or services, to Internet service providers and/or any other party involved in the illegal posting.
<h2>User Conduct and Operation</h2>
Fictionate.me’s Code of Conduct (part of these Terms of Service) states the expected values of the Fictionate.me community in order to ensure the Site and Services stay safe and civilized.
Fictionate.me requests that users refrain from doing any of the following:
<ul>
<li>Violate any law, such as by infringing on the rights of others or breaching a contract or legal duty.</li>
<li>Do harm to any computer, such as by distributing software viruses or other items (e.g. code, film, programs) designed to interfere with the proper function of software, hardware, or equipment on the Site.</li>
<li>Damage or disrupt Fictionate.me, such as by interfering with the proper function of Services through bypassing security measures, gaining access to a system, data, password, or other information, or performing an action to impose an unreasonable load, as determined by Fictionate.me, on Fictionate.me’s infrastructure or third-party providers.</li>
<li>Scrape Fictionate.me through the use of software, device, or method (manual or automated) to “spider,” “crawl,” or otherwise remove content from the Site or Services.</li>
<li>Use the Site, Services, or content to the effect of competing with or displacing the market for Fictionate.me.</li>
<li>Take content without permission from Fictionate.me, and similarly, change, translate, reproduce, distribute, or create derivative works of content without explicit permission of the author of such content.</li>
<li>Steal Fictionate.me’s intellectual property or attempt to access source code, underlying ideas, or algorithms through taking apart or reverse engineering the Site or Services.</li>
</ul>
<h2>Account Deletion</h2>
Upon closing, an account is first deactivated, then deleted. While deactivated, the account is not viewable on Fictionate.me. All comments and message board posts on community forums remain but are made anonymous (and will remain even after permanent deletion). An account may be restored within 6 months of deactivation if accidentally or wrongfully deactivated. Comments and message board posts will be re-associated to the account. When this 6-month period ends, Fictionate.me will permanently delete the deactivated account from their systems.
Fictionate.me may suspend or terminate an account or block access to all or part of Services at any time or for any reason. In this case, Fictionate.me will notify the corresponding user via the associated email or on next sign-in attempt.
<h2>Disclaimers, Limitations of Liability, and Indemnification</h2>
The following subsections apply to the maximum extent permitted under any relevant laws; to users in jurisdictions where the disclaimer of implied warranties or limitation of liability in contracts is not allowed, the contents of this section do not apply. No statement herein limits any rights users may have which may not lawfully be limited.
WARRANTY. Use of Fictionate.me’s Services and content is at users’ risk and discretion. Services and content are not warranted, no matter the type (express or implied). In addition to any warranty implied by a course of dealing or performance, Fictionate.me specifically disclaims the following implied warranties:
<ul>
<li>Merchantability.</li>
<li>Merchantable quality.</li>
<li>Fitness for a particular purpose.</li>
<li>Availability.</li>
<li>Security.</li>
<li>Title.</li>
<li>Non-infringement.</li>
</ul>
CONTENT RESPONSIBILITY. It is the responsibility of the author/originator to monitor and control their content, either publicly posted or privately transmitted via Services. In addition, users may be exposed to offensive, harmful, inaccurate, inappropriate, or mislabeled or deceptive content. Fictionate.me does not endorse, support, represent, or guarantee completeness, truthfulness, accuracy, or liability of content of communications or opinions expressed or posted via Services.
RELEASE. Fictionate.me is released from all claims, demands, and damages, whether known, suspected, or disclosed, resulting from, or involving disputes between users or users and a third party related to use of Services.

FICTIONATE.ME LIABILITY. In any event, Fictionate.me is liable for damages at the maximum total of $100 USD. Fictionate.me is not liable for any damages resulting from use or involvement of Services and/or content, including such circumstances and items as follows:
<ul>
<li>Services are hacked or unavailable.</li>
<li>Any type of damages (i.e. direct, indirect, punitive, incidental, consequential, special, and/or exemplary), no matter the type of claim or loss (i.e. breach of contract, tort – including negligence – breach of warranty, etc.).</li>
<li>Lost profits, data, or revenue.</li>
<li>Conduct or content or other users of third parties on the Site or Services.</li>
</ul>
USER LIABILITY. If Fictionate.me is involved in a lawsuit as a result of user action, or if a user violates the Terms of Service and causes monetary responsibility of Fictionate.me, that user is liable for proceeding compensation of liabilities, losses, claims, and expenses (e.g. reasonable legal fees and costs). In the event that Fictionate.me uses their right to assume exclusive defense and control of any matter otherwise subject to this clause, users will cooperate and assist Fictionate.me in claiming defenses.

THIRD-PARTY WEBSITES. Users who access any third-party websites using links that may be present on the Site and Services do so at their own risk. Fictionate.me neither controls nor endorses such websites.
<h2>General Terms and Conditions</h2>
CHANGES. These Terms of Service are subject to change at any time. In the event that changes are made, users will be notified on the Site or via email. Continued use of Services after such change is understood to indicate agreement and consent to be bound by the new Terms. A user should cease using Services and/or close their account if they disagree with any changes to the Terms of Service and do not consent to be bound by the new Terms.
SCOPE OF AGREEMENT. These Terms of Service, along with any document referenced herein, comprise the entire agreement between Fictionate.me and users regarding Services and supplant prior agreement(s) between these parties. Failure by Ficionate.me to exercise or enforce a given right or provision of these Terms of Service does not affect the validity of that right or provision. Similarly, any right or provision found to be invalid or unenforceable will be limited or removed to the minimum extent necessary and such finding does not affect the validity of the remaining rights and provisions.
LAW AND JURISDICTION. Fictionate.me encourages users to give Fictionate.me the opportunity to rectify any issues prior to instituting legal proceedings. If legal proceedings are instituted, these Terms of Service will be governed by, and interpreted in accordance with, laws of the United States applying to, and laws of, the state of Delaware. A dispute or claim in any way involving these Terms of Service will be addressed in a court of Delaware.
RIGHT TO ASSIGN. These Terms of Service may not be assigned, transferred, or sublicensed by any user without the prior written consent of Fictionate.me. However, Fictionate.me may assign, transfer, or delegate any of its rights and obligations under these Terms of Service without notice or user consent.
      </StaticLayout>
    </AppLayout>
  )
};

export default TermsOfServicePage;

import React, { useMemo, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ChevronRight16 } from '@carbon/icons-react';
import { Loading, Tag } from 'carbon-components-react';
import classNames from 'classnames';

import cs from './ListenPage.module.scss';
import history from 'src/history';
import { RES_PATH } from 'src/config/environment';
import { useIfMobileScreen, useLayoutAdjustments } from 'src/hooks';
import TopMenu from './TopMenu';
import Playlist from './Playlist';
import AudioPlayer from './AudioPlayer';
import ProgressOverlay from './ProgressOverlay';
import usePlayer from './usePlayer';
import Seeker from './Seeker';
import ChapterName from './ChapterName';
import BufferingOverlay from 'src/components/reader/ListenPage/BufferingOverlay';
import { fetchShareState, putBookShared, clearShareState } from 'src/modules/actions/books.actions';
import ReactGA from 'react-ga';
import { fetchBook as fetchBookApi } from 'src/modules/api/books.api';

const ListenPage = ({ match, shareState, ...actions }) => {
  const { params: { bookUuid, chapterUuid } } = match;
  const [mode, setMode] = React.useState('player');
  const isMobile = useIfMobileScreen();
  const soundState = usePlayer(bookUuid, chapterUuid);
  const [bookObject, setBookObject] = React.useState(null);
  const { bookLoading: loading, book, currentChapter } = soundState;
  const {orientationState: screenOrientation, contentHeight} = useLayoutAdjustments();
  const imageRef = React.useRef();
  const [coverStyle, setCoverStyle] = React.useState({});
  const { clearShareState } = actions;

  const _onClose = () => {
    history.push(`/books/${bookUuid}`);
  };

  const fetchBook = useCallback(async () => {
    const res = await fetchBookApi(bookUuid);
    if (res?.status !== 200) throw new Error("cannot fetch book");
    setBookObject(res.data);
  }, [bookUuid]);


  const key = useMemo(() => {
    return screenOrientation;
  }, [screenOrientation]);
  useEffect(() => {
    setBookObject(book)
  }, [book]);

  useEffect(() => {
    ReactGA.event({ category: 'Listen', action: `${bookUuid}`});
  }, [bookUuid]);

  useEffect(() => {
    if (book && !book.narration) {
      history.replace(`/books/${bookUuid}`);
    }
  }, [book, bookUuid]);

  useEffect(() => {
    if (isMobile && imageRef?.current) {
      setCoverStyle({ visibility: 'hidden' });
      setTimeout(() => {
        setCoverStyle({
          width: imageRef.current.offsetHeight * 0.8,
          visibility: 'visible'
        })
      }, 1000);
    }
  }, [isMobile, imageRef, currentChapter])

  useEffect(() => {
    return () => {
      clearShareState();
    }
  }, [clearShareState])

  const hasProcessingChapters = (bookObject?.chapters?.filter(c => c?.audioState?.state === "inProgress") ?? []).length > 0;
  if(hasProcessingChapters) {
    setTimeout(fetchBook, 2000);
  }
  
  return (
    <>
      <ProgressOverlay {...soundState}/>
      <BufferingOverlay {...soundState}/>
      <Loading active={loading} />
      <div className={cs.content} style={{ height: contentHeight}} key={key}>
        <TopMenu isMobile={isMobile}
                 mode={mode}
                 onToggleMode={setMode}
                 onClose={_onClose}
                 bookUuid={bookUuid}
                 {...soundState}
                 book={bookObject}
        />
        <div className={cs.container}>
          <div className={cs.coverImage} ref={imageRef}
               style={{ ...coverStyle, backgroundImage: book ? `url(${RES_PATH + book.image})` : '', backgroundSize: 'cover'}}
          >
            <Loading small withOverlay={false} active={loading}/>
          </div>
          <div className={cs.listeningBlock}>
            <div className={cs.bookName}>
              {book?.name}
            </div>
            <div
              className={cs.author}
              onClick={() => history.push(`/writers/${book.author.uuid}`)}
            >
              {book?.author.name}
              <ChevronRight16 style={{verticalAlign: 'middle'}} />
            </div>
            <div className={cs.line} />
            {book?.tags?.length > 0 &&
              <div className={cs.tags}>
                {book?.tags.map((tag, i) =>
                  <Tag key={i}
                       type='cool-gray'
                       style={{cursor: 'pointer'}}
                       onClick={() => history.push(`/tags/${tag}`)}
                  >
                    {tag}
                  </Tag>
                )}
              </div>
            }
            <ChapterName {...soundState} book={bookObject}/>
            <Seeker {...soundState} book={bookObject} />
            <AudioPlayer {...soundState} {...actions} book={bookObject && { ...bookObject, share: shareState }} />
            <Playlist className={classNames(cs.playlist, { [cs.hidden]: isMobile && mode === 'player' })}
                      isMobile={isMobile}
                      mode={mode}
                      setMode={setMode}
                      {...soundState}
                      book={bookObject}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default connect(
  state => ({
    shareState: state.books.shareState
  }),
  dispatch =>
    bindActionCreators({ fetchShareState, putBookShared, clearShareState }, dispatch)
)(ListenPage);

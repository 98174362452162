import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import Button from 'src/components/_shared/DesignV2/Button';
import { Loading } from 'carbon-components-react';

import { redirectToCheckout, redirectToCoinbaseCheckout } from 'src/modules/actions/payment.actions';
import { isBookPriceTier, priceAmount, isChapterPriceTier } from 'src/modules/model/book';
import cx from './BuyButton.module.scss';

const BuyButton = ({ book, chapter, showOriginalPrice, className, style, ...actions }) => {
  const coinbasePending = book?.pricing.state === "coinbasePending";
  const [visible, setVisible] = useState(false);

  const onClick = () => {
    if (coinbasePending) { return; }
    if(!visible) {
      setVisible(true);
    }
  };

  const onCryptoClick = () => {
    if (coinbasePending) { return; }
    actions.redirectToCoinbaseCheckout(book, chapter);
  };

  const onCreditClick = () => {
    if (coinbasePending) { return; }
    actions.redirectToCheckout(book, chapter);
  };

  const _toggleOverlay = (event) => {
    setVisible(false);
  };

  return (
    <>
      {visible &&
      <div className={cx.overlay} onClick={_toggleOverlay}></div>}
        <div className={classNames(cx.base, className)} style={{...style}}>
          <Button kind='cta'
                  className={cx.btn}
                  onClick={onClick}
                  size='small'
          >
            {coinbasePending ? <Loading style={{display:'inline-block'}} small={true} withOverlay={false}/> : `Buy for ${priceAmount(book)}`}
            { book.price.paymentType === 'monthly' ? <> / month</> : null }
          </Button>
          {coinbasePending ? 
            <div className={cx.originalPrice}>
            Waiting for coinbase confirmation
            </div> :
            book.originalPrice && (book.price?.amount !== undefined) && book.originalPrice.amount > book.price.amount && showOriginalPrice &&
              <div className={cx.originalPrice}>
                price without coupon ${book.originalPrice.amount / 100}
              </div>
          }
          {visible &&
          <div style={{position: 'absolute', width: '100%', marginTop: '8px', padding: '8px',backgroundColor:'#343537', borderRadius: '5px', zIndex: 1000}}>
            <Button kind='cta'
                    className={cx.btn}
                    onClick={onCreditClick}
                    size='small'
            >
            Pay with Credit Cards
            </Button> 
            <div style={{height: '8px'}}/>
            <Button kind='cta'
                    className={cx.btn}
                    style={{marginTop: '8px'}}
                    onClick={onCryptoClick}
                    size='small'
            >
            Pay with Crypto
            </Button>
          </div>
          }
        </div>
    </>
  )
};

BuyButton.defaultProps = {
  showOriginalPrice: false,
  className: ''
};

BuyButton.propTypes = {
  book: PropTypes.shape({
    purchased: PropTypes.bool.isRequired,
    price: PropTypes.shape({
      paymentType: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired
    }),
    originalPrice: PropTypes.shape({
      amount: PropTypes.number
    })
  }).isRequired,
  chapter: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    purchased: PropTypes.bool
  }),
  redirectToCheckout: PropTypes.func.isRequired,
  showOriginalPrice: PropTypes.bool,
  className: PropTypes.any
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ redirectToCheckout, redirectToCoinbaseCheckout }, dispatch);
}

export default connect(() => ({}), mapDispatchToProps)(BuyButton);

export const canBuy = (book, chapter) => {
  return ((isBookPriceTier(book) && !book.purchased) || (isChapterPriceTier(book) && chapter && !chapter.purchased))
};

const LOCAL_ADDR = 'https://157a-2601-646-9701-bc60-bd1d-40dd-7fe3-83cf.ngrok.io'//'http://localhost:8181';
// const REMOTE_ADDR = 'http://fictionate.me:8181';
const REMOTE_ADDR = 'https://api.fictionate.me';
const STAGING_ADDR = "https://api.staging.fictionate.me";

const LOCAL_STRIPE_KEY = 'pk_test_CeN6C50zELl25m84g1xpFAJM';
const REMOTE_STRIPE_KEY = 'pk_live_tlm7lEAsbgA1Oh51ESePs0TB';

const LOCAL_PAYOUT_KEY = 'ca_H5nL9izHioN2nLs7LixcG7ntP7gEUaGL';
const REMOTE_PAYOUT_KEY = 'ca_H5nLS1sg08Xpn7YJBpfZaLPfVmONJUpW';

const configs = {
  production: {
    "default": {
      API_URL: REMOTE_ADDR,
      RES_PATH: REMOTE_ADDR,
      STRIPE_KEY: REMOTE_STRIPE_KEY,
      PAYOUT_KEY: REMOTE_PAYOUT_KEY
    },
    staging: {
      API_URL: STAGING_ADDR,
      RES_PATH: STAGING_ADDR,
      STRIPE_KEY: REMOTE_STRIPE_KEY,
      PAYOUT_KEY: REMOTE_PAYOUT_KEY
    }
  },
  development: {
    local: {
      API_URL: LOCAL_ADDR,
      RES_PATH: LOCAL_ADDR,
      STRIPE_KEY: LOCAL_STRIPE_KEY,
      PAYOUT_KEY: LOCAL_PAYOUT_KEY
    },
    remote: {
      API_URL: REMOTE_ADDR,
      RES_PATH: REMOTE_ADDR,
      STRIPE_KEY: REMOTE_STRIPE_KEY,
      PAYOUT_KEY: REMOTE_PAYOUT_KEY
    },
    staging: {
      API_URL: STAGING_ADDR,
      RES_PATH: STAGING_ADDR,
      STRIPE_KEY: LOCAL_STRIPE_KEY,
      PAYOUT_KEY: LOCAL_PAYOUT_KEY
    }
  }
}

const config = configs[process.env.NODE_ENV][process.env.REACT_APP_SERVER];

module.exports = {
  API_URL: config['API_URL'],
  RES_PATH: config['RES_PATH'],
  STRIPE_KEY: config['STRIPE_KEY'],
  PAYOUT_KEY: config['PAYOUT_KEY']
};

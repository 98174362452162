import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import { getAuthHeader } from 'src/modules/api/utils';
import ps from 'src/modules/model/payout-state';
import { PAYOUT_KEY } from 'src/config/environment';

export function useStripePayouts() {
  const [loading, setLoading] = useState(false);
  const [payoutState, setPayoutState] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchState() {
      setLoading(true);
      try {
        const res = await axios.get('/me/payouts/state', getAuthHeader());
        if (res?.status === 200) {
          setPayoutState(res.data.state);
        } else {
          setPayoutState(ps.API_ERROR);
        }
      } catch (e) {
        setPayoutState(e?.response?.data?.error ?? ps.API_ERROR);
      } finally {
        setLoading(false);
      }
    }

    fetchState();
  }, []);

  const configurePayouts = useCallback(async (email) => {
    setLoading(true);
    try {
      const res = await axios.get('me/payouts/start', getAuthHeader());
      const state = res.data.state;
      const urlToRedirect = `https://connect.stripe.com/express/oauth/authorize?
redirect_uri=payouts_configured&
client_id=${PAYOUT_KEY}&
state=${state}&
suggested_capabilities[]=transfers&
suggested_capabilities[]=card_payments&
stripe_user[email]=${encodeURIComponent(email)}&
stripe_user[url]=${encodeURIComponent("https://fictionate.me")}&
stripe_user[business_type]=individual&
stripe_user[business_name]=${encodeURIComponent("Fictionate.me")}`;
      document.location.href = urlToRedirect;
    } catch (e) {
      setError("Cannot get configuration url.");
      setLoading(false);
    }
  }, []);

  const openDashboard = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get('/me/payouts/dashboard', getAuthHeader());
      document.location.href = res.data.link;
    } catch (e) {
      setError("Cannot open the dashboard.");
      setLoading(false);
    }
  }, [])

  useEffect(() => {
    let handler = null;
    if (error) {
      handler = setTimeout(() => setError(null), 3000);
    }
    return () => {
      if (handler) {
        clearTimeout(handler);
      }
    }
  }, [error])

  return {
    loading, payoutState, configurePayouts, error, openDashboard
  }
}

import axios from 'axios';

import { getAuthHeader } from 'src/modules/api/utils';
import { ACTION_TYPES } from 'src/modules/reducers/search.reducer';
import ReactGA from 'react-ga';

export const globalSearchInput = (text) => ({ type: ACTION_TYPES.GLOBAL_SEARCH_TYPE, text });

export function doSearch(query) {
  ReactGA.event({
    category: 'search',
    action: query
  });
  return async dispatch => {

    const dispatchRejected = message => dispatch({ type: ACTION_TYPES.GLOBAL_SEARCH_REJECTED, message });

    try {
      dispatch({ type: ACTION_TYPES.GLOBAL_SEARCH_PENDING });

      const response = await axios.get(`/search?query=${query}`, getAuthHeader());

      if (!response) {
        dispatchRejected('connection with server is lost, try again later');
        return;
      }

      if (response.status !== 200) {
        dispatchRejected('server respond with error');
        console.log(response);
        return;
      }

      dispatch({ type: ACTION_TYPES.GLOBAL_SEARCH_FULFILLED, data: {...response.data} });

    } catch (err) {
      console.log("ERROR", err);
      dispatchRejected('');
    }
  }
}

export const prepareSearching = () => ({ type: ACTION_TYPES.PREPARE_SEARCHING });

export const localQueryInput = localQuery => ({ type: ACTION_TYPES.GLOBAL_SEARCH_LOCAL_QUERY_TYPE, localQuery });

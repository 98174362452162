import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FormPage from 'src/components/_shared/form/FormPage';
import history from 'src/history';
import { FORM_ERROR } from 'final-form';

import { fetchMyBlogPost, createBlogPost, updateMyBlogPost, forgetPost } from 'src/modules/actions/blog.actions';

export const EditBlogPost = props => {
  const { content, match, id, loading, lastPage, ...actions } = props;
  const { fetchMyBlogPost, createBlogPost, updateMyBlogPost, forgetPost } = actions;
  let object = content
 
  useEffect(() => {
    const uuid = match.params.uuid;
    if (uuid) {
      fetchMyBlogPost(uuid);
    }
    return () => {
      forgetPost();
    };
  }, [
    fetchMyBlogPost, 
    match,
    forgetPost
  ]);

  return (
    <>
      <FormPage
        loading={loading} 
        crumbs={[{title: "My Blog Posts", link: "/me/posts"}]}
        properties={[
          {
            title: "Published*", type: "toggle", id: "state", 
            convertFrom: (val) => val === "published", 
            convertTo: (val) => val ? "published" : "draft"
          },
          {
            title: "Blog Surface*", type: "dropdown", id: "surface", 
            default: "blog",
            dropdown: {
              options: [
                { id: "blog", labelText: "Blog", value: "blog" },
                { id: "static", labelText: "Static", value: "static" }
              ]
            }
          },
          { title: "Unique Id*", type: "text", id: "id" },
          { title: "Name*", type: "text", id: "name" },
          { title: "Summary*", type: "textArea", id: "summary" },
          { title: "Content", type: "htmlEditor", id: "content" },
          { 
            title: "Image", type: "image", id: "image",
            convertTo: (imageData) => { 
              if(imageData.startsWith("data")){
                return ({ data: imageData.split(',')[1], type: 'jpg' })
              } else {
                return imageData
              }
            }
          },
          { title: "Scheduled", type: "date", id: "scheduled", default: Date.now() / 1000},
        ]}
        value={object}
        submit={(value)=> {
            const uuid = match.params.uuid;
            if (uuid) {
              return updateMyBlogPost(uuid, value).then(({success}) => {
                if(success) {
                  history.push(`/me/posts`);
                } else {
                  return { [FORM_ERROR]: "Failed to save blog post" };
                }
              })
            } else {
              return createBlogPost(value).then(({success}) => {
                if(success) {
                  history.push(`/me/posts`);
                } else {
                  return { [FORM_ERROR]: "Failed to update blog post" };
                }
              })
            }
        }}
       />
    </>
  )
};

EditBlogPost.propTypes = {
};

function mapStateToProps(state) {
  return {
    ...state.blogPost,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchMyBlogPost, createBlogPost, updateMyBlogPost, forgetPost }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBlogPost);
import React, { useRef } from 'react';
import PT from 'prop-types';
import { Button } from 'carbon-components-react';
import { ChevronRight20 } from '@carbon/icons-react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FixedSizeList as List } from 'react-window';

import cs from './styles.module.scss';
import CustomScrollbarsVirtualList from 'src/components/MainPage/CustomScrollbarsVirtualList';
import SectionName from 'src/components/_shared/DesignV2/SectionName';
import Href from "src/components/_shared/Href";

const SwipingList = (props) => {  
  const ref = useRef(null);
  const {items, children, width} = props;

  return (
    <Slider
      ref={ref}
      dots={false}
      infinite={false}
      slidesToShow={1}
      slidesToScroll={items.length === 1 ? 2 : 1}
      variableWidth
      arrows={false}
      afterChange={index => {
        if (index >= items.length && ref) {
          setTimeout(() => ref.current.slickGoTo(0), 10);
        }
      }}
    >
      {
        items.map((item, index) => (
          <div style={{ width }} key={index}>
            {children(item)}
          </div>
        ))
      }

      <div style={{ width }} />
      <div style={{ width }} />
      <div style={{ width }} />
    </Slider>
  )
};

SwipingList.propTypes = {
  items: PT.array.isRequired,
  width: PT.number.isRequired,
  children: PT.func.isRequired
};

const ScrollingList = (props) => {
  const { screenWidth, width, height, items, children } = props;

  return (
    <List
      width={screenWidth}
      itemSize={width}
      height={height}
      itemCount={items.length}
      layout="horizontal"
      outerElementType={CustomScrollbarsVirtualList}
    >
      {
        ({index, style}) => {
          const item = items[index];
          return (
            <div style={style}>
              {
              children(item)
              }
            </div>
          );
        }
      }
    </List>
  )
};

ScrollingList.propTypes = {
  items: PT.array.isRequired,
  width: PT.number.isRequired,
  height: PT.number.isRequired,
  screenWidth: PT.number.isRequired,
  children: PT.func.isRequired
};

const HorizontalItemList = (props) => (
  <>
    {
      props.items.length > 0 ?
        <>
          <div className={cs.header}>
          <SectionName name={props.title}>
            <Button size='small'
                    kind='ghost'
                    className={cs.seeAllButton}
            >
              <Href href={props.pushTo}>
                {props.actionName}
              </Href>
              <ChevronRight20/>
            </Button>
          </SectionName>
          </div>
          { props.isMobileDevice ? <SwipingList {...props} /> : <ScrollingList {...props} /> }
        </> : null
    }
  </>
);

HorizontalItemList.defaultProps = {
  screenWidth: 0,
  items: []
};

HorizontalItemList.propTypes = {
  title: PT.string.isRequired,
  pushTo: PT.string.isRequired,
  actionName: PT.string.isRequired,
  isMobileDevice: PT.bool.isRequired,
  items: PT.array.isRequired,
  width: PT.number.isRequired,
  height: PT.number.isRequired,
  screenWidth: PT.number.isRequired,
  children: PT.func.isRequired
};

export default HorizontalItemList;

import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';

import { useQuery } from 'src/hooks';

export function usePurchaseGA(book, ) {
  const [gaSent, setGaSent] = useState(false);
  const query = useQuery();

  useEffect(() => {
    if (book?.priceTier) {
      if (query.get('session') === 'success' && !gaSent) {

        let action;
        switch (book.priceTier) {
          case 'tier1c':
            action = "purchase_chapter";
            break;
          case 'tier5m':
            action = "purchase_subscribe";
            break;
          case 'tier10b':
            action = "purchase_book";
            break
          default:
            action = null;
        }

        if (action) {
          ReactGA.event({
            category: 'user',
            action
          });
        } else {
          console.log(`Unknown price tier ${book.priceTier}`);
        }

        setGaSent(true);
      }
    }
  }, [query, gaSent, book]);
}

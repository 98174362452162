import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import {
  FacebookShareButton, FacebookIcon, FacebookShareCount,
  TwitterShareButton, TwitterIcon,
  WhatsappShareButton, WhatsappIcon,
  PinterestShareButton, PinterestIcon,
  RedditShareButton, RedditIcon
} from 'react-share';

import cs from './LikeSharing.module.scss';
import CopyLink from 'src/components/CopyLink';
import { RES_PATH } from 'src/config/environment';

const LikeSharing = ({ bookId, author, bookName, image }) => {
  const shareUrl = `https://fictionate.me/books/${bookId}`;
  const exampleImage = RES_PATH + image;

  const _onShared = () => {
    ReactGA.event({ category: 'Share', action: 'Like'});
  }

  return (
    <div className={cs.sharing}>
      <div className={cs.text}>
        Enjoyed reading?<br/>
        Support <strong>{author.name}</strong>
      </div>


      <div className={cs.socialButtons} onClick={_onShared}>
        <div className={cs.socialItem}>
          <FacebookShareButton
            url={shareUrl}
            quote={bookName}
            className={cs.shareButton}
          >
            <FacebookIcon size={32} round/>
          </FacebookShareButton>
          <div className={cs.shareCount}>
            <FacebookShareCount
              url={shareUrl}/>
          </div>
        </div>

        <div className={cs.socialItem}>
          <TwitterShareButton
            url={shareUrl}
            title={bookName}
            className={cs.shareButton}
          >
            <TwitterIcon size={32} round/>
          </TwitterShareButton>
        </div>

        <div className={cs.socialItem}>
          <WhatsappShareButton
            url={shareUrl}
            title={bookName}
            separator=":: "
            className={cs.shareButton}
          >
            <WhatsappIcon size={32} round/>
          </WhatsappShareButton>
        </div>

        <div className={cs.socialItem}>
          <PinterestShareButton
            url={shareUrl}
            media={exampleImage}
            className={cs.shareButton}
          >
            <PinterestIcon size={32} round/>
          </PinterestShareButton>
        </div>

        <div className={cs.socialItem}>
          <RedditShareButton
            url={shareUrl}
            title={bookName}
            windowWidth={660}
            windowHeight={460}
            className={cs.shareButton}
          >
            <RedditIcon size={32} round/>
          </RedditShareButton>
        </div>
      </div>

      <CopyLink url={shareUrl} book={bookId} shortening="book"/>
    </div>
  )
}

LikeSharing.propTypes = {
  author: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  bookId: PropTypes.string.isRequired,
  bookName: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired
}

export default LikeSharing;

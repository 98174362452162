import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setIsAuthorSelected } from 'src/modules/actions/authentication.actions';
import AppLayout from 'src/layout/AppLayout';
import Content from 'src/components/_shared/Content';
import Collage from 'src/components/_shared/Collage';
import cs from './BecomeWriterPage.module.scss';
import { genMailto } from 'src/modules/api/utils';
import { FaceSatisfied32, CurrencyDollar32, Chat32, Events32 } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import history from 'src/history';

const mailtoLink = genMailto();

const _BecomeWriterPage = ({ writer, isAuthenticated, ...actions }) => {
    const mailTo = () => window.location.href = `mailto:${mailtoLink}`;

    const navigateAction = React.useCallback(() => {
        if (isAuthenticated && writer) {
            history.push('/my-books')
        } else if (isAuthenticated) {
            history.push('/become');
        } else {
            actions.setIsAuthorSelected(true);
            history.push('/register', { from: '/my-books'});
        }
    }, [actions, writer, isAuthenticated]);

    const actionTitle = 
        (isAuthenticated && writer) ? "Upload your first book" :
        (isAuthenticated) ? "Become a writer" :
        "Register as a writer"

    return (
        <AppLayout>
            <Content>
                <Collage/>
                <div className={cs.content}>
                    <div className={cs.center}>
                        <div className={cs.h1}>Your books. Your fans. Your<br/> team. All together now.</div>
                        <button className={cs.registerButton} onClick={navigateAction}>{actionTitle}</button>
                        <br/><button className={cs.contactUs} onClick={mailTo}>Contact us</button>
                    </div>

                    <div className={cs.block}>
                        <FaceSatisfied32 />
                        <h2>Create your own books online <span className={cs.yellow}>free of charge</span></h2>
                        <p>Do you want to learn how to self-publish your own ebook for free? Fictionate.me is an online self-publishing website where you can publish your own novels for chapter by chapter and get paid royalties.</p>
                        <p>No need to chase a publisher or register an ISBN. Create your own book online, deliver your book to your fans instantly, while you are working on it. Get instant feedback from your readers to help guide your writing.</p>
                        <p><Link to={'/s/terms-of-service'} className={cs.yellow}>Read terms and conditions &#8594;</Link></p>
                    </div>

                    <div className={cs.block}>
                        <CurrencyDollar32 />
                        <h2>Publish your own ebook and start <span className={cs.green}>earning money</span> instantly</h2>
                        <p>Start earning on your in-progress ebooks or your completed manuscripts. No need to constrain yourself with a publisher.</p>
                        <p>Choose a payment model for your readers - Pay per book, pay per chapter, or have a monthly subscription. You earn the way you want to while giving your readers a choice of how to pay.</p>
                        <p>Our payments provider charges  2.9%+30¢. Fictionate.me doesn’t charge anything. All payments from your readers go directly to you.</p>
                    </div>

                    <div className={cs.block}>
                        <Chat32 />
                        <h2>Get <span className={cs.blue}>feedback from fans</span> and critics</h2>
                        <p>Write a book online and prove your ideas early. Get feedback from your fans and from critics. Improve your creations and get popular!</p>
                        <p><button className={classNames(cs.blue, cs.buttonAsLink)} onClick={navigateAction}>Upload your first book</button></p>
                    </div>

                    <div className={cs.block}>
                        <Events32 />
                        <h2>Grow Your <span className={cs.yellow}>Audience</span></h2>
                        <p>Acquire new readers by getting your book into top charts. Reach fans of your genre.</p>
                        <p>Transfer your readers and fans from one book to another.</p>
                    </div>
                </div>
            </Content>
        </AppLayout>
    )
}

_BecomeWriterPage.propTypes = {
    writer: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    setIsAuthorSelected: PropTypes.func
}

export const BecomeWriterPage = connect(
    state => ({
        writer: state.authentication.account?.writer,
        isAuthenticated: state.authentication.isAuthenticated
    }),
    dispatch => bindActionCreators({ setIsAuthorSelected }, dispatch)
)(_BecomeWriterPage);
import * as chaptersApi from 'src/modules/api/chapters.api';
import { ACTION_TYPES } from 'src/modules/reducers/chapters.reducer';
import history from 'src/history';

const rejected = () => ({ type: ACTION_TYPES.FETCH_CHAPTER_REJECTED });

export function fetchChapter(chapterUuid) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.FETCH_CHAPTER_PENDING });

    const response = await chaptersApi.getChapter(chapterUuid);
    if (response.status === 200) {
      let chapter = { ...response.data };

      if (chapter.fileType === 'html') {
        delete chapter.filePath;
        chapter.text = await chaptersApi.getChapterText(chapterUuid);
      }

      dispatch({ type: ACTION_TYPES.FETCH_CHAPTER_FULFILLED, chapter});
    } else {
      dispatch(rejected());
      history.push('/notFound');
    }
  }
}

export const forgetChapter = () => ({ type: ACTION_TYPES.FORGET_CHAPTER });

export function deleteChapter(chapterUuid, index) {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.DELETE_CHAPTER_PENDING });

    const response = await chaptersApi.deleteChapter(chapterUuid);
    if (response.status === 200) {
      dispatch({ type: ACTION_TYPES.DELETE_CHAPTER_FULFILLED, index });
    } else {
      dispatch({ type: ACTION_TYPES.DELETE_CHAPTER_REJECTED });
    }
  }
}

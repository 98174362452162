import axios from 'axios';
import { getAuthHeader } from 'src/modules/api/utils';
import { ACTION_TYPES } from 'src/modules/reducers/payment.reducer';
import * as Book from 'src/modules/model/book';
import { STRIPE_KEY } from 'src/config/environment';
import ReactGA from 'react-ga';
import history from 'src/history';

export const redirectToCheckout = (book, chapter) => {
  ReactGA.event({ category: 'Payment', action: 'Open-Stripe'});

  return async dispatch => {
    dispatch({ type: ACTION_TYPES.REQUEST_CHECKOUT_PENDING });

    try {
      const stripe = window.Stripe(STRIPE_KEY);

      let response;
      if (Book.isChapterPriceTier(book) && chapter) {
        response = await axios.post(`/chapters/${chapter.uuid}/purchase`, {}, getAuthHeader());
      } else if (Book.isBookPriceTier(book)) {
        response = await axios.post(`/books/${book.uuid}/subscribe`, {}, getAuthHeader());
      } else {
        console.log('Cannot get stripe\'s sessionId: malformed parameters #redirectToCheckout.');
        dispatch({ type: ACTION_TYPES.REQUEST_CHECKOUT_REJECTED, error: 'Cannot complete payment' });
        return;
      }

      const { sessionId } = response.data;
      if(sessionId !== undefined)  {
        const result = await stripe.redirectToCheckout({ sessionId });
        if (result && result.error) {
          ReactGA.event({ category: 'Payment', action: 'Canceled'});
          dispatch({ type: ACTION_TYPES.REQUEST_CHECKOUT_REJECTED, error: result.error.message });
        } else {
          ReactGA.event({ category: 'Payment', action: 'Completed'});
        }
      } else if (response.data.purchased === true) {
        dispatch({ type: ACTION_TYPES.REQUEST_CHECKOUT_FULFILLED });
      } else {
        ReactGA.event({ category: 'Payment', action: 'Canceled'});
        dispatch({ type: ACTION_TYPES.REQUEST_CHECKOUT_REJECTED, error: "Purchase failed" });
      }

    } catch (error) {
      console.log(error);
      dispatch({ type: ACTION_TYPES.REQUEST_CHECKOUT_REJECTED, error: 'Cannot complete payment.' });
    }
  }
};

export const redirectToCoinbaseCheckout = (book, chapter) => {
  ReactGA.event({ category: 'Payment', action: 'Open-Coinbase'});

  return async dispatch => {
    dispatch({ type: ACTION_TYPES.REQUEST_CHECKOUT_PENDING });
    try {
      let response;
      if (Book.isChapterPriceTier(book) && chapter) {
        response = await axios.post(`/chapters/${chapter.uuid}/purchase`, {type: 'coinbase'}, getAuthHeader());
      } else if (Book.isBookPriceTier(book)) {
        response = await axios.post(`/books/${book.uuid}/subscribe`, {type: 'coinbase'}, getAuthHeader());
      } else {
        console.log('Cannot get stripe\'s sessionId: malformed parameters #redirectToCheckout.');
        dispatch({ type: ACTION_TYPES.REQUEST_CHECKOUT_REJECTED, error: 'Cannot complete payment' });
        return;
      }

      const { coinbaseURL } = response.data;
      if(coinbaseURL !== undefined)  {
        window.location.href = coinbaseURL
      } else if (response.data.purchased === true) {
        dispatch({ type: ACTION_TYPES.REQUEST_CHECKOUT_FULFILLED });
      } else {
        ReactGA.event({ category: 'Payment', action: 'Canceled'});
        dispatch({ type: ACTION_TYPES.REQUEST_CHECKOUT_REJECTED, error: "Purchase failed" });
      }

    } catch (error) {
      console.log(error);
      dispatch({ type: ACTION_TYPES.REQUEST_CHECKOUT_REJECTED, error: 'Cannot complete payment.' });
    }
  }
};

export const redirectToWriterCheckout = (uuid, amount) => {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.REQUEST_CHECKOUT_PENDING });

    try {
      const stripe = window.Stripe(STRIPE_KEY);

      let response = await axios.post(`/writers/${uuid}/pay`, {amount}, getAuthHeader());
      const { sessionId } = response.data;

      if(sessionId !== undefined)  {
        const result = await stripe.redirectToCheckout({ sessionId });
        if (result && result.error) {
          ReactGA.event({ category: 'Payment', action: 'Canceled'});
          dispatch({ type: ACTION_TYPES.REQUEST_CHECKOUT_REJECTED, error: result.error.message });
        } else {
          ReactGA.event({ category: 'Payment', action: 'Completed'});
        }
      } else if (response.data.purchased === true) {
        dispatch({ type: ACTION_TYPES.REQUEST_CHECKOUT_FULFILLED });
      } else {
        ReactGA.event({ category: 'Payment', action: 'Canceled'});
        dispatch({ type: ACTION_TYPES.REQUEST_CHECKOUT_REJECTED, error: "Purchase failed" });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: ACTION_TYPES.REQUEST_CHECKOUT_REJECTED, error: 'Cannot complete payment.' });
    }
  }
};

export const closeError = () => {
  return { type: ACTION_TYPES.CLOSE_ERROR };
};

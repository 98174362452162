import React from 'react';
import PropTypes from 'prop-types';
import history from 'src/history';
import { OverflowMenu, OverflowMenuItem, Tag } from 'carbon-components-react';
import { RequestQuote24 } from '@carbon/icons-react';
import cn from 'classnames';

import cs from './ChapterItem.module.scss';
import { dateOpts } from 'src/modules/api/utils';
import Delimiter from 'src/components/_shared/Delimiter';

export const ChapterItem = ({ match, chapter, onDeleteChapter, onTogglePublished, onAddChapterAbove, onSharableLink, onPreview }) => {

  const onEdit = () => history.push(`${match.url}/chapter/${chapter.uuid}/edit`);

  return (
    <>
      <div className={cs.chapter}>

        {
          !chapter.published && <div style={{ flexShrink: 0, paddingTop: '0.25rem'}}><RequestQuote24/></div>
        }

        <div style={{flexShrink: 0}}>
          <div className={cn(cs.chapterTitle, {[cs.draftTitle]: !chapter.published})}>
            {chapter.name}
          </div>
          <div className={cs.chapterAdded}>
            { chapter.published ?
              new Date(chapter.created * 1000).toLocaleDateString("en-US", dateOpts):
              'Draft'
            }
          </div>
        </div>

        { chapter.free &&
          <Tag type='green' style={{marginLeft: '1rem', whiteSpace: "nowrap"}}>free</Tag>
        }

        <div style={{flexBasis: '100%'}} />

        <OverflowMenu
          direction='top'
          flipped={true}
        >
          <OverflowMenuItem
            itemText="Edit"
            onClick={onEdit}
          />
          <OverflowMenuItem
            itemText={chapter.published ? "Unpublish" : "Publish"}
            onClick={onTogglePublished}
          />
          <OverflowMenuItem
            itemText="Delete"
            hasDivider
            isDelete
            onClick={onDeleteChapter}
          />
          <OverflowMenuItem
            itemText="Add chapter above"
            hasDivider
            isDelete
            onClick={onAddChapterAbove}
          />
          <OverflowMenuItem
            itemText="Share Access"
            hasDivider
            isDelete
            onClick={onSharableLink}
          />
          <OverflowMenuItem
            itemText="Preview"
            hasDivider
            isDelete
            onClick={onPreview}
          />
        </OverflowMenu>

      </div>
      <Delimiter/>
    </>
  )
};

ChapterItem.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }),
  chapter: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    published: PropTypes.bool.isRequired,
    created: PropTypes.number.isRequired,
    free: PropTypes.bool.isRequired
  }),
  onDeleteChapter: PropTypes.func.isRequired,
  onTogglePublished: PropTypes.func.isRequired
};

export default ChapterItem;

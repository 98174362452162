import React from 'react';

import common from 'src/layout/form/common.module.scss';

export const RowWrapper = ({ title, children, titleClass = ''}) => (
  <div className="bx--row">
    <div style={{minWidth:"120px"}}className={`bx--col-lg-2 bx--col-md-2 ${common.fieldTitle} ${titleClass}`}>
      {title}
    </div>
    <div className="bx--col-lg-10 bx--col-md-6">
      {children}
    </div>
  </div>
);

export default RowWrapper;

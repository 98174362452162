import axios from 'axios';
import { ACTION_TYPES } from 'src/modules/reducers/profile.reducer';
import { getAuthHeader } from 'src/modules/api/utils';

export function fetchProfile() {
  return async (dispatch, getState) => {
    dispatch({ type: ACTION_TYPES.FETCH_PROFILE_PENDING });
    try {
      const { writer, uuid } = getState().authentication.account;
      const response = await axios.get((writer ? '/writers/' : '/users/') + uuid, getAuthHeader());
      dispatch({ type: ACTION_TYPES.FETCH_PROFILE_FULFILLED, data: response.data });
    } catch (error) {
      dispatch({ type: ACTION_TYPES.FETCH_PROFILE_REJECTED });
    }
  }
}

export const clearProfileData = () => ({ type: ACTION_TYPES.CLEAN_PROFILE_DATA });

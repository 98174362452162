import React from 'react';
import { FormGroup } from 'carbon-components-react';
import { Select, SelectItem } from 'carbon-components-react';

import styles from './styles.module.scss';

export const InputDropdown = ({ input, options, onChange, meta, placeholder, disabled = false }) => {

  return (
    <FormGroup legendText=''>
      <Select
        {...input}
        id={input.id || ''}
        invalid={meta.touched && meta.error && true}
        invalidText={meta.error}
        className={styles.wide}
        labelText=''
        disabled={disabled}
        onChange={ event => {
          input.onChange(event)
          onChange(event)
        }}
      >
      <SelectItem disabled hidden value="nr" text="Choose an option" />
      {options.map((value, index) => {
          return <SelectItem value={value.id} text={value.labelText} key={index}/>
      })}
      </ Select>
    </FormGroup>
  )
};

export default InputDropdown;

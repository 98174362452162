import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useResizeEffect } from 'src/hooks'

// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AppLayout from 'src/layout/AppLayout';
import { fetchTagsHome, fetchTagsBooks, fetchTagsBooksNextPage } from 'src/modules/actions/tags.actions';
import { Loading } from 'carbon-components-react';
import BookCell from 'src/components/_shared/DesignV2/BookCell';
import BookCellSmall from 'src/components/_shared/DesignV2/BookCellSmall';
import BookCellWide from 'src/components/_shared/DesignV2/BookCellWide';
import PageHeader from 'src/components/_shared/PageHeader';

import SectionHeader from 'src/components/_shared/DesignV2/SectionHeader';
import HorizontalItemList from 'src/components/_shared/DesignV2/HorizontalItemList';
import { Dropdown } from 'carbon-components-react';
import { RES_PATH } from 'src/config/environment';

import cx from './TagsHome.module.scss';
import cn from 'classnames';

export const TagsHome = props => {
  var options= [{name: "Latest First", id: "date"}, {name: "Popular First", id: "readersRaiting"}]

  const { match, content, lastPage, ...actions } = props;
  const { loading, pageLoading } = props;
  const containerRef = useRef(null);
  const rootRef = useRef(null);
  const [gridWidth, setGridWidth] = useState(1024);
  const [isMobile, setIsMobile] = useState(true);
  const { fetchTagsHome, fetchTagsBooks, fetchTagsBooksNextPage } = actions;
  const [tags] = useState([match.params.tag]);
  const [sorting, setSorting] = useState(options[0]);
  const [booksCount, setBooksCount] = useState(content.books.length);
  if(booksCount !== content.books.length){
    setBooksCount(content.books.length)
  }
  var object = content

  const resizeListener = useCallback(() => {
    if (containerRef?.current) {
      setGridWidth(containerRef.current.offsetWidth);
    }
    if (rootRef?.current) {
      setIsMobile(rootRef.current.offsetWidth < 628);
    }
  }, [containerRef]);

  useResizeEffect(resizeListener);

  useEffect(() => {
    resizeListener();
  }, [containerRef, resizeListener]);

  useEffect(() => {
    console.log("Tags: " + tags);
    fetchTagsHome(tags);
  }, [match, fetchTagsHome, tags]);

  const didScroll = useCallback((e) => {
    const bottom = e.target.scrollingElement.scrollHeight - e.target.scrollingElement.scrollTop === e.target.scrollingElement.clientHeight;
    if(bottom && !pageLoading && !lastPage){
      fetchTagsBooksNextPage(tags, sorting.id, booksCount)
    }
  }, [fetchTagsBooksNextPage, sorting, tags, pageLoading, lastPage, booksCount]);

  useEffect( () => {
    window.addEventListener('scroll', didScroll);
    return () => {
      window.removeEventListener('scroll', didScroll);
    }
  }, [didScroll]);

  const action = useCallback((params) => {
    setSorting(params.selectedItem)
    fetchTagsBooks(tags, params.selectedItem.id)
  }, [fetchTagsBooks, tags]);

  return (
    <>
      <AppLayout>
        {loading ?
        <Loading key="bottomProgress" style={{margin: "0 auto"}} active={loading || pageLoading} withOverlay={false}/> :
        <>
        <PageHeader image={object.image && `${RES_PATH}${object.image}`} name={object.name}/>
        <div className={cn(cx.center)} ref={rootRef}>
          <div className={cn(cx.wrapper)}>
            <div className={cn(cx.content)} ref={containerRef}>
              {
                object.content.map((item, index) => 
                (item.items.length > 0) ?
                <div key={index} className={cn(cx.row)}>
                  <SectionHeader className={cn(cx.sectionHeader)} title={item.name} />
                  <HorizontalItemList items={item.items}
                                      width={isMobile ? (226 + 32) : (226 + 48)}
                                      height={425}
                                      screenWidth={gridWidth}
                                      >
                                        {(item) => (
                                          <div className={cn(cx.bookCell)}>
                                            <BookCell book={item}/>
                                          </div>
                                        )}
                  </HorizontalItemList>
                </div> :
                <></>
                )
              }
              <SectionHeader className={cn(cx.sectionHeader)} title={`All ${object.name}`} />
              <div className={cn(cx.selection)}>
                <Dropdown
                  className={cn(cx.dropdown)}
                  id="sorting"
                  type="inline"
                  label=""
                  items={options}
                  itemToString={(item) => ( <span className="test" style={{ color: '#6DA4FD' }}>{item.name}</span>)}
                  selectedItem={sorting}
                  onChange={action}
                />
              </div>
              <div className={cn(cx.grid)}>
              {object.books.map((item, index) => 
                <div key={item.book.uuid} className={cn(cx.item)}>
                  {isMobile ? <BookCellWide  book={item}/> : <BookCellSmall  book={item}/>}
                </div>
              )}
              </div>
              <Loading key="bottomProgress" style={{margin: "0 auto"}} active={loading || pageLoading} withOverlay={false}/>
            </div>
          </div>
        </div>
        </>
        }
      </AppLayout>
    </>
  )
};

TagsHome.propTypes = {
};

function mapStateToProps(state) {
  return {
    ...state.tags
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchTagsHome, fetchTagsBooks, fetchTagsBooksNextPage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TagsHome); 
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, TextArea, Loading } from 'carbon-components-react';

import cx from './Comments.module.scss';
import * as api from './comments-api';
import { openNameRequiredDialog } from 'src/modules/actions/modal.actions';
import { toggleComments } from 'src/modules/actions/books.actions';
import Delimiter from 'src/components/_shared/Delimiter';
import CommentItem from 'src/components/reader/BookPage/CommentItem';

const MAX_COMMENT_LENGTH = 600;

const Comments = ({ bookUuid, userName, commentsShown, ...actions }) => {
  const [comment, setComment] = useState('');
  const [count, setCount] = useState(0);
  const [commentList, setCommentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);

  const _onChange = e => {
    setComment(e.target.value);
    setCount(e.target.value.length);
  };

  const _open = () => {
    if (!userName || userName.trim().length === 0) {
      actions.openNameRequiredDialog();
    } else {
      actions.toggleComments(true);
    }
  };

  const _cancel = () => {
    actions.toggleComments(false);
    setComment('');
    setCount(0);
  };

  const _submit = () => {
    if (!comment || comment.trim().length === 0) {
      return;
    }

    setSubmit(true);
    api.postComment(bookUuid, comment)
      .then(newComment => {
        if (newComment) {
          setCommentList([newComment, ...commentList]);
        }
        setComment('');
        setCount(0);
        actions.toggleComments(false);
      })
      .finally(() => {
        setSubmit(false);
      });
  };

  useEffect(() => {
    if (!bookUuid) return;
    setLoading(true);
    api.getComments(bookUuid)
      .then(comments => {
        setCommentList(comments || []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [bookUuid]);

  useEffect(() => {
    return () => {
      actions.toggleComments(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cx.base}>
      <div className={cx.title}>
        <div>
          Comments
        </div>
        { !commentsShown &&
          <div>
            <Button kind='ghost'
                    size='small'
                    onClick={_open}>
              Add a Comment
            </Button>
          </div>
        }
      </div>

      <Delimiter style={{ marginBottom: 18, marginTop: 2 }} />

      {commentsShown &&
        <div className={cx.commentBlock}>

          <div className={cx.symbols}>
            <div>
              New comment
            </div>
            <div>
              {count}/{MAX_COMMENT_LENGTH}
            </div>
          </div>

          <TextArea labelText=''
                    maxlength={MAX_COMMENT_LENGTH}
                    value={comment}
                    onChange={_onChange}
                    disabled={submit}
          />

          <div className={cx.submitButtons}>
            <Button size='small' onClick={_submit} disabled={submit}>Submit</Button>
            <Button kind='secondary' size='small' onClick={_cancel} disabled={submit}>Cancel</Button>
          </div>
        </div>
      }

      <div className={cx.comments}>
        {loading && <div className={cx.loader}><Loading withOverlay={false} small/></div>}
        {!loading && commentList?.length === 0 && <div className={cx.noComments}>No comments yet.</div>}
        {!loading && commentList?.length > 0 &&
        <div>
          {commentList.map((comment, index) => {
            return (
              <CommentItem key={index} {...comment} />
            )
          })}
        </div>
        }
      </div>

    </div>
  );
};

Comments.propTypes = {
  bookUuid: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  openNameRequiredDialog: PropTypes.func.isRequired,
  toggleComments: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return  {
    userName: state.authentication.account.name,
    commentsShown: state.books.commentsShown
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ openNameRequiredDialog, toggleComments }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Comments);

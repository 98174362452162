import React from 'react';
import PropTypes from 'prop-types';
import { Button, InlineNotification } from 'carbon-components-react';
import { Information20 } from '@carbon/icons-react';
import { connect } from 'react-redux';

import Layout from 'src/layout/ContentLayout';
import { useStripePayouts } from 'src/hooks';
import { Loading } from 'carbon-components-react';
import ps from 'src/modules/model/payout-state';
import PayoutTip from 'src/components/_shared/PayoutTip';

import cs from './earnings-page.module.scss';

const EarningsPage = ({ email }) => {
  const { loading, payoutState, configurePayouts, openDashboard, error } = useStripePayouts();

  const _configure = () => {
    configurePayouts(email);
  }
  return (
    <>
      <Loading active={loading}
               withOverlay
      />

      <Layout title='Earnings'>
        { !loading && payoutState ?
          <div className={cs.earnings}>
            {payoutState === ps.NOT_CONFIGURED ?
              <>
                <div className={cs.info}><Information20/> In order to receive payments, Stripe payouts need to be configured.</div>
                <div><Button kind='tertiary' onClick={_configure}>Configure Stripe Payouts</Button></div>
              </> :
              payoutState === ps.CONFIGURED ?
                <>
                  <div><Button kind='tertiary' onClick={openDashboard}>Open Stripe Dashboard</Button></div>
                </> :

                <PayoutTip payoutState={payoutState} onClick={_configure}/>
            }
            {error &&
              <InlineNotification kind='warning'
                                          lowContrast
                                          hideCloseButton
                                          title={error}
              />
            }
          </div> : null
        }
      </Layout>
    </>
  )
}

EarningsPage.defaultProps = {
  email: ''
}

EarningsPage.propTypes = {
  email: PropTypes.string.isRequired
}

function mapStateToProps(state) {
  return {
    email: state.authentication.account.email
  }
}

export default connect(mapStateToProps)(EarningsPage);

import React from 'react';
import PropTypes from 'prop-types';

import { Button, Breadcrumb, BreadcrumbItem} from 'carbon-components-react';
import Layout, { actionsCommon } from 'src/layout/ContentLayout';
import { Link } from 'react-router-dom';
import Content from 'src/components/_shared/Content';
import FormContent from 'src/components/_shared/form/FormContent';

export const FormPage = props => {
    const {title, crumbs, ...properties} = props

    const Crumbs = ({ crumbs }) => (
        <Breadcrumb className={actionsCommon.breadcrumbContainer}>
            {crumbs.map((crumb, index) => (
                <BreadcrumbItem key={index} className={actionsCommon.breadcrumbItem}>
                    <Link to={crumb.link}>{crumb.title}</Link>
                </BreadcrumbItem>
            ))}
        </Breadcrumb>
    );
  
    let submitTrigger = null;
  
    return (
        <Content>
            <Layout
            title={title}
            renderAction={() => <Button disabled={properties.loading} onClick={() => submitTrigger()}>{ properties.value ? "Save" : "Create" }</Button>}
            breadcrumb={crumbs && <Crumbs crumbs={crumbs}/>}
            >
                <FormContent {...properties} setTrigger={(trigger) => submitTrigger = trigger} />
            </Layout>
        </Content>
    );
};


FormPage.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.any, // initial value
    submit: PropTypes.func.isRequired,
    crumbs: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        link: PropTypes.string
    })).isRequired
};

export default FormPage;
import { ACTION_TYPES as CHAPTERS_ACTION_TYPES } from 'src/modules/reducers/chapters.reducer';

export const ACTION_TYPES = {
  FETCH_BOOK_PENDING: 'books/FETCH_BOOK_PENDING',
  FETCH_BOOK_FULFILLED: 'books/FETCH_BOOK_FULFILLED',
  FETCH_BOOK_REJECTED: 'books/FETCH_BOOK_REJECTED',

  FETCH_BOOK_SERIES_FULFILLED: 'books/FETCH_BOOK_SERIES_FULFILLED',
  FETCH_BOOK_SERIES_OBJECT_FULFILLED: 'books/FETCH_BOOK_SERIES_OBJECT_FULFILLED',
  FORGET_BOOK_SERIES_OBJECT_FULFILLED: 'books/FORGET_BOOK_SERIES_OBJECT_FULFILLED',

  FETCH_SHARED_STATE_PENDING: 'book/FETCH_SHARED_STATE_PENDING',
  FETCH_SHARED_STATE_FULFILLED: 'book/FETCH_SHARED_STATE_FULFILLED',
  FETCH_SHARED_STATE_REJECTED: 'book/FETCH_SHARED_STATE_REJECTED',
  CLEAR_SHARED_STATE: 'book/CLEAR_SHARED_STATE',

  PUT_BOOK_SHARED: 'book/PUT_BOOK_SHARED',

  DELETE_BOOK_PENDING: 'books/DELETE_BOOK_PENDING',
  DELETE_BOOK_FULFILLED: 'books/DELETE_BOOK_FULFILLED',
  DELETE_BOOK_REJECTED: 'books/DELETE_BOOK_REJECTED',

  CREATE_CHAPTER_PENDING: 'books/CREATE_CHAPTER_PENDING',
  CREATE_CHAPTER_FULFILLED: 'books/CREATE_CHAPTER_FULFILLED',
  CREATE_CHAPTER_REJECTED: 'books/CREATE_CHAPTER_REJECTED',

  FETCH_CHAPTER_PENDING: 'books/FETCH_CHAPTER_PENDING',
  FETCH_CHAPTER_FULFILLED: 'books/FETCH_CHAPTER_FULFILLED',
  FETCH_CHAPTER_REJECTED: 'books/FETCH_CHAPTER_REJECTED',

  UPDATE_CHAPTER_PENDING: 'books/UPDATE_CHAPTER_PENDING',
  UPDATE_CHAPTER_FULFILLED: 'books/UPDATE_CHAPTER_FULFILLED',
  UPDATE_CHAPTER_REJECTED: 'books/UPDATE_CHAPTER_REJECTED',

  ADD_MYBOOKS_PENDING: 'books/ADD_MYBOOKS_PENDING',
  ADD_MYBOOKS_FULFILLED: 'books/ADD_MYBOOKS_FULFILLED',
  ADD_MYBOOKS_REJECTED: 'books/ADD_MYBOOKS_REJECTED',

  REMOVE_MYBOOKS_PENDING: 'books/REMOVE_MYBOOKS_PENDING',
  REMOVE_MYBOOKS_FULFILLED: 'books/REMOVE_MYBOOKS_FULFILLED',
  REMOVE_MYBOOKS_REJECTED: 'books/REMOVE_MYBOOKS_REJECTED',

  START_SUBSCRIPTION_PENDING: 'books/START_SUBSCRIPTION_PENDING',
  START_SUBSCRIPTION_FULFILLED: 'books/START_SUBSCRIPTION_FULFILLED',
  START_SUBSCRIPTION_REJECTED: 'books/START_SUBSCRIPTION_REJECTED',

  TOGGLE_COMMENTS: 'books/TOGGLE_COMMENTS',

  FORGET_BOOK: 'books/FORGET_BOOK',

  IMPORT_EPUB_PENDING: 'books/IMPORT_EPUB_PENDING',
  IMPORT_EPUB_FULFILLED: 'books/IMPORT_EPUB_FULFILLED',
  IMPORT_EPUB_REJECTED: 'books/IMPORT_EPUB_REJECTED'
};

export const defaultState = {
  loading: false,
  book: null,
  error: false,
  commentsShown: false,
  shareState: null
};

function fetchBook(state, action) {
  switch(action.type) {
    case ACTION_TYPES.FETCH_BOOK_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.FETCH_BOOK_FULFILLED:
      return {
        ...state,
        loading: false,
        book: action.book,
        error: false
      };
    case ACTION_TYPES.FETCH_BOOK_REJECTED:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}

function fetchSeries(state, action) {
  switch(action.type) {
    case ACTION_TYPES.FETCH_BOOK_SERIES_FULFILLED:
      return {
        ...state,
        series: action.series,
      };
    case ACTION_TYPES.FETCH_BOOK_SERIES_OBJECT_FULFILLED:
      return {
        ...state,
        seriesObject: action.seriesObject,
      };
    case ACTION_TYPES.FORGET_BOOK_SERIES_OBJECT_FULFILLED:
      return {
        ...state,
        seriesObject: null,
      };
    default:
      return state;
  }
}
function createChapter(state, action) {
  switch (action.type) {
    case ACTION_TYPES.CREATE_CHAPTER_PENDING:
      return {
        ...state
      };
    case ACTION_TYPES.CREATE_CHAPTER_FULFILLED:
      return {
        ...state
      };
    case ACTION_TYPES.CREATE_CHAPTER_REJECTED:
      return {
        ...state
      };
    default:
      return state;
  }
}

function fetchChapter(state, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CHAPTER_PENDING:
      return {
        ...state
      };
    case ACTION_TYPES.FETCH_CHAPTER_FULFILLED:
      return {
        ...state
      };
    case ACTION_TYPES.FETCH_CHAPTER_REJECTED:
      return {
        ...state
      };
    default:
      return state;
  }
}

function deleteChapter(state, action) {
  switch (action.type) {
    case CHAPTERS_ACTION_TYPES.DELETE_CHAPTER_PENDING:
      return {
        ...state,
        loading: true
      };
    case CHAPTERS_ACTION_TYPES.DELETE_CHAPTER_FULFILLED:
      return {
        ...state,
        loading: false,
        book: state.book && {
          ...state.book,
          chapters: state.book.chapters.filter((item, index) => index !== action.index)
        }
      };
    case CHAPTERS_ACTION_TYPES.DELETE_CHAPTER_REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

function updateChapter(state, action) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_CHAPTER_PENDING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.UPDATE_CHAPTER_FULFILLED:
      return {
        ...state,
        loading: false,
        book: state.book && {
          ...state.book,
          chapters: state.book.chapters.map((item, index) => {
            if (index !== action.index) {
              return item;
            }

            return {
              ...item,
              ...action.chapter
            }
          })
        }
      };
    case ACTION_TYPES.UPDATE_CHAPTER_REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

function fetchSharedState(state, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_SHARED_STATE_PENDING:
      return state;
    case ACTION_TYPES.FETCH_SHARED_STATE_REJECTED:
      return state;
    case ACTION_TYPES.FETCH_SHARED_STATE_FULFILLED:
      return {
        ...state,
        book: state.book && {
          ...state.book,
          share: { ...action.payload }
        },
        shareState: { ...action.payload }
      }
    case ACTION_TYPES.CLEAR_SHARED_STATE:
      return {
        ...state,
        shareState: null
      }
    default:
      return state;
  }
}

function putBookShared(state, action) {
  switch (action.type) {
    case ACTION_TYPES.PUT_BOOK_SHARED:
      return {
        ...state,
        book: state.book && {
          ...state.book,
          share: state.book.share && { ...state.book.share, status: 'shared' }
        }
      }
    default:
      return state;
  }
}

function importEpub(state, action) {
  switch (action.type) {
    case ACTION_TYPES.IMPORT_EPUB_PENDING:
      return state;
    case ACTION_TYPES.IMPORT_EPUB_FULFILLED:
      return state;
    case ACTION_TYPES.IMPORT_EPUB_REJECTED:
      return state;
    default:
      return state;
  }
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.FORGET_BOOK:
      return {
        ...state,
        book: null,
        error: false
      };

    case ACTION_TYPES.FETCH_BOOK_PENDING:
    case ACTION_TYPES.FETCH_BOOK_FULFILLED:
    case ACTION_TYPES.FETCH_BOOK_REJECTED:
      return fetchBook(state, action);

    case ACTION_TYPES.FETCH_BOOK_SERIES_FULFILLED:
    case ACTION_TYPES.FETCH_BOOK_SERIES_OBJECT_FULFILLED:
    case ACTION_TYPES.FORGET_BOOK_SERIES_OBJECT_FULFILLED:
      return fetchSeries(state, action)
      
    case ACTION_TYPES.CREATE_CHAPTER_PENDING:
    case ACTION_TYPES.CREATE_CHAPTER_FULFILLED:
    case ACTION_TYPES.CREATE_CHAPTER_REJECTED:
      return createChapter(state, action);

    case ACTION_TYPES.FETCH_CHAPTER_PENDING:
    case ACTION_TYPES.FETCH_CHAPTER_FULFILLED:
    case ACTION_TYPES.FETCH_CHAPTER_REJECTED:
      return fetchChapter(state, action);

    case CHAPTERS_ACTION_TYPES.DELETE_CHAPTER_PENDING:
    case CHAPTERS_ACTION_TYPES.DELETE_CHAPTER_FULFILLED:
    case CHAPTERS_ACTION_TYPES.DELETE_CHAPTER_REJECTED:
      return deleteChapter(state, action);

    case ACTION_TYPES.UPDATE_CHAPTER_PENDING:
    case ACTION_TYPES.UPDATE_CHAPTER_FULFILLED:
    case ACTION_TYPES.UPDATE_CHAPTER_REJECTED:
      return updateChapter(state, action);

    case ACTION_TYPES.FETCH_SHARED_STATE_FULFILLED:
    case ACTION_TYPES.FETCH_SHARED_STATE_REJECTED:
    case ACTION_TYPES.FETCH_SHARED_STATE_PENDING:
    case ACTION_TYPES.CLEAR_SHARED_STATE:
      return fetchSharedState(state, action);

    case ACTION_TYPES.PUT_BOOK_SHARED:
      return putBookShared(state, action);

    case ACTION_TYPES.TOGGLE_COMMENTS:
      return {
        ...state,
        commentsShown: action.show
      };

    case ACTION_TYPES.IMPORT_EPUB_PENDING:
    case ACTION_TYPES.IMPORT_EPUB_FULFILLED:
    case ACTION_TYPES.IMPORT_EPUB_REJECTED:
      return importEpub(state, action);

    default:
      return state;
  }
}

import React, { useState, useRef, useEffect, useCallback } from "react";
import PT from 'prop-types';
import { connect } from 'react-redux';
import {
  Favorite16,
  Favorite20,
  Favorite24,
  Favorite32,
  FavoriteFilled16,
  FavoriteFilled20,
  FavoriteFilled24,
  FavoriteFilled32
} from '@carbon/icons-react';
import ReactGA from 'react-ga';
import cn from 'classnames';

import cx from './like.module.scss';
import history from 'src/history';
import { toggleLiked } from 'src/modules/actions/books.actions';
import LikeSharing from './LikeSharing';
import { useResizeEffect } from 'src/hooks';

const Like = ({
                uuid, liked, likes,
                criticLikes, showLikes, enableSharing,
                size, style, counterStyle,
                author, name, image,
                likesFirst = true,
                likesChanged, likedChanged, className, ...attr }) => {
  const [prevLiked, setPrevLiked] = useState(liked);
  const [prevLikes, setPrevLikes] = useState(likes);
  const [innerLiked, setInnerLiked] = useState(liked);
  const [innerLikes, setInnerLikes] = useState(likes);
  const [overlay, setOverlay] = useState(false);
  const heartRef = useRef(null);
  const [heartPos, setHeartPos] = useState(null);

  useEffect(() => {
      setHeartPos({
        top: heartRef?.current?.offsetTop,
        left: heartRef?.current?.offsetLeft
      });
  }, [heartRef]);

  useResizeEffect(useCallback(() => {
    if (heartRef.current) {
      setHeartPos({
        top: heartRef.current.offsetTop,
        left: heartRef.current.offsetLeft
      });
    }
  }, [heartRef]));

  if (liked !== prevLiked) {
    setInnerLiked(liked);
    setPrevLiked(liked);
  }

  if (likes !== prevLikes) {
    setInnerLikes(likes);
    setPrevLikes(likes);
  }

  const LikedIcon = () => {
    switch (size) {
      case 'xs': return <FavoriteFilled16/>;
      case 's': return <FavoriteFilled20/>;
      case 'm': return <FavoriteFilled24/>;
      case 'l': return <FavoriteFilled32/>;
      default: return <FavoriteFilled16/>;
    }
  };

  const LikeIcon = () => {
    switch (size) {
      case 'xs': return <Favorite16/>;
      case 's': return <Favorite20/>;
      case 'm': return <Favorite24/>;
      case 'l': return <Favorite32/>;
      default: return <Favorite16/>;
    }
  };

  const _toggleLiked = async (e) => {
    e.stopPropagation();
    if (!attr.isAuthenticated) {
      history.push('/login', { from: history.location })
    } else {
      toggleLiked(uuid, innerLiked).then(() => {
        if (!innerLiked) {
          ReactGA.event({
            category: 'user',
            action: 'like'
          });
        }
      });
      const _likes = innerLikes + (innerLiked ? -1 : 1);
      const _liked = !innerLiked;
      if (!innerLiked && enableSharing) {
        setOverlay(true);
        if (window.pageYOffset + window.innerHeight < (heartPos?.top ?? 483) + 250) {
          window.scrollTo(0, (heartPos?.top ?? 483) - window.innerHeight + 250)
        }
      }
      if (enableSharing) {
        setOverlay(!innerLiked);
      }
      setInnerLikes(_likes);
      setInnerLiked(_liked);
      likesChanged(_likes);
      likedChanged(_liked);
    }
  };

  const _toggleOverlay = event => {
    event.stopPropagation();
    setOverlay(false);
  }

  const _onBubbleClick = event => {
    event.stopPropagation();
  }

  return (
    <>
      {overlay &&
      <div className={cx.overlay} onClick={_toggleOverlay}>
      </div>}
      <div className={cn(cx.base, { [cx.liked]: innerLiked }, className)}
           style={{...style, zIndex: 4}}
           ref={heartRef}
      >
        {
          likesFirst ? 
          <>
            {showLikes && <div className={cx.likes} style={counterStyle}>{innerLikes > 0 ? innerLikes : ''}</div>}
            <div className={cx.icon} onMouseUp={_toggleLiked}> {innerLiked ? <LikedIcon/> : <LikeIcon/>} </div>
          </> :
          <>
            <div className={cx.icon} onMouseUp={_toggleLiked}> {innerLiked ? <LikedIcon/> : <LikeIcon/>} </div>
            {showLikes && <div className={cx.likes} style={counterStyle}>{innerLikes > 0 ? innerLikes : ''}</div>}
          </>
        }
        {overlay && 
          <div style={{position: 'absolute', width: 1, height: 1, zIndex: 4, bottom: 0 }}>
            <div className={cx.speechBubble}
                onClick={_onBubbleClick}
            >
              <LikeSharing author={author}
                          bookId={uuid}
                          bookName={name}
                          image={image}
              />
            </div>
          </div>
        }
      </div>
    </>
  )
};

export default connect(state => ({ isAuthenticated: state.authentication.isAuthenticated }))(Like);

Like.defaultProps = {
  liked: false,
  likes: 0,
  criticLikes: 0,
  showLikes: true,
  size: 'xs',
  style: {},
  counterStyle: {},
  likesChanged: () => {},
  likedChanged: () => {},
  enableSharing: false
};

Like.propTypes = {
  uuid: PT.string.isRequired,
  liked: PT.bool.isRequired,
  likes: PT.number.isRequired,
  criticLikes: PT.number.isRequired,
  showLikes: PT.bool.isRequired,
  showZero: PT.bool,
  size: PT.oneOf(['xs', 's', 'm', 'l']),
  isAuthenticated: PT.bool.isRequired,
  style: PT.object,
  counterStyle: PT.any,
  likesChanged: PT.func,
  likedChanged: PT.func,
  enableSharing: PT.bool,
  author: PT.shape({
    uuid: PT.string,
    name: PT.string
  }),
  name: PT.string,
  image: PT.string,
  className: PT.string
};

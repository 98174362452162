import axios from 'axios';
import * as ls from './local-storage.api';
import { getAuthHeader } from 'src/modules/api/utils';

export async function postBook(book) {
  const account = ls.getAccount();
  if (account) {
    const writerUuid = account.uuid;
    try {
      return await axios.post(`/writers/${writerUuid}/books`, book, getAuthHeader());
    } catch (error) {
      return error.response;
    }
  }
  return {}
}

export async function getBooks() {
  const account = ls.getAccount();
  if (account) {
    const writerUuid = account.uuid;
    try {
      return await axios.get(`/writers/${writerUuid}/books`, getAuthHeader());
    } catch (error) {
      return error.response;
    }
  } else return {};
}

export async function getWriter(uuid) {

  try {
    return await axios.get(`/writers/${uuid}`, getAuthHeader())
  } catch (error) {
    return error.response;
  }
}

export async function getJobStatus(uuid) {
  try {
    return await axios.get(`/jobs/${uuid}`, getAuthHeader())
  } catch (error) {
    return error.response;
  }
}

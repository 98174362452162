import React, {
    useCallback
    } from 'react';
import PropTypes from 'prop-types';

import { FormGroup, Toggle, Loading, InlineNotification } from 'carbon-components-react';
import { Form, Field } from 'react-final-form';
import RowWrapper from 'src/layout/form/RowWrapper';
import InputText from 'src/components/_shared/form/InputText';
import InputArea from 'src/components/_shared/form/InputArea';
import TextEditor from 'src/components/_shared/form/TextEditor';
import InputSelect from 'src/components/_shared/form/InputSelect';
import InputDropdown from 'src/components/_shared/form/InputDropdown';
import InputTimePicker from 'src/components/_shared/form/InputTimePicker';
import InputImage from 'src/components/_shared/form/InputImage';
import { RES_PATH } from 'src/config/environment';

import styles from './styles.module.scss';

const required = value => (value ? undefined : 'Required');
function requiredDropdown(emptyId) {
    return value => ((value === emptyId) ? 'Required' : undefined);
}

export const FormContent = props => {
    const {properties, value, submit, loading, setTrigger, style} = props
    const [dropdownStates, setDropdownStates] = React.useState({});
    const valueObject = value ?? {}
    const _submitForm = useCallback((values) => {
        var object = {}
        for (var i = 0; i < properties.length; i++) {
            let property = properties[i];
            var objectValue = undefined
            if(property.type === "dropdown") {
                objectValue = property.dropdown.options.find( element => element.id === values[property.id]).value
            } else {
                objectValue = values[property.id];
            }
            if(objectValue !== undefined) {
                if(property.convertTo) {
                    objectValue = property.convertTo(objectValue);
                }
            }
            if(objectValue === valueObject[property.id]) {
                continue;
            }
            object[property.id] = objectValue;
        }
        return submit(object);
    }, [properties, valueObject, submit]);

    const _getInitialValues = useCallback((object) => {
        if (!object) return null;

        var values = {}
        for (var i = 0; i < properties.length; i++) {
            let property = properties[i];
            var value = object[property.id];
            if(value) {
                if(property.convertFrom) {
                    value = property.convertFrom(value);
                }
            }
            values[property.id] = value;
        }
        return values;
    }, [properties]);
    
    return (
        <Form
            style={style}
            initialValues={_getInitialValues(valueObject)}
            onSubmit={_submitForm}
            subscription={{ submitting: true, pristine: true, submitError: true }}
            render={({ handleSubmit, submitting, submitError, values }) => {
                setTrigger(handleSubmit)
                const disable = submitting || loading;

                return (
                <>
                    {disable && <Loading small={true} />}
                    <form onSubmit={handleSubmit}>
                        <div >
                            {properties.map((property, index) => {
                                switch (property.type) {
                                    case "toggle":
                                        return (
                                            <RowWrapper title={property.title}>
                                                <FormGroup legendText="">
                                                <Field
                                                    id={property.id}
                                                    name={property.id}
                                                    type='checkbox'
                                                    component={({ input }) =>
                                                    <Toggle { ... input }
                                                            labelA={property.type.labelA}
                                                            labelB={property.type.labelB}
                                                            className={styles.toggle}
                                                    /> }
                                                    disabled={disable}
                                                />
                                                </FormGroup>
                                            </RowWrapper>
                                        );
                                    case "text":
                                        return (
                                            <RowWrapper title={property.title}>
                                                <Field
                                                    id={property.id}
                                                    name={property.id}
                                                    validate={property.required && required}
                                                    component={InputText}
                                                    placeholder={(property.text && property.text.placeHolder) ? property.text.placeHolder : "" }
                                                    disable={disable}
                                                />
                                            </RowWrapper>
                                        );
                                    case "textArea":
                                        return (
                                            <RowWrapper title={property.title}>
                                                <Field
                                                id={property.id}
                                                name={property.id}
                                                validate={property.required && required}
                                                component={InputArea}
                                                placeholder={(property.textArea && property.textArea.placeHolder) ? property.textArea.placeHolder : "" }
                                                disabled={disable}
                                                />
                                            </RowWrapper>
                                        );
                                    case "htmlEditor":
                                        return (
                                            <RowWrapper title={property.title} titleClass={styles.uploadTitle}>
                                                <Field
                                                id={property.id} 
                                                name={property.id}
                                                component={TextEditor}
                                                />
                                            </RowWrapper>
                                        );
                                    case "radioButtons":
                                        return (
                                            <RowWrapper title={property.title}>
                                                <FormGroup legendText="">
                                                <Field id={property.id}
                                                        name={property.id}
                                                        component={InputSelect}
                                                        options={property.radioButtons.options}
                                                        defaultSelected={property.default}
                                                        disabled={disable}
                                                />
                                                </FormGroup>
                                            </RowWrapper>
                                        );
                                    case "dropdown":
                                        return (
                                            <RowWrapper title={property.title}>
                                                <Field name={property.id}
                                                    style={{ width: '100%' }}
                                                    validate={property.required && requiredDropdown(property.default)}
                                                    defaultValue={property.default}
                                                    disabled={disable}
                                                    component={InputDropdown}
                                                    onChange={ event => {
                                                        var states = dropdownStates
                                                        let value = property.dropdown.options.find( element => element.id === event.target.value).value
                                                        states[property.id] = value
                                                        setDropdownStates(states)
                                                    }}
                                                    options={property.dropdown.options}
                                                    >
                                                </ Field>
                                                <div id='rating-description' style={{ marginTop: '20px', marginBottom: '40px' }}>
                                                { 
                                                property.dropdown.options.find( element => element.id === dropdownStates[property.id]) &&
                                                property.dropdown.options.find( element => element.id === dropdownStates[property.id]).desc
                                                }
                                                </div>
                                        </RowWrapper>
                                        );
                                    case "image":
                                        return (
                                            <div className="bx--col-lg-8 bx--col-md-4">
                                                <FormGroup legendText={property.title}>
                                                <Field
                                                    id={property.id}
                                                    name={property.id}
                                                    component={InputImage}
                                                    initialSrc={valueObject && valueObject[property.id] && RES_PATH + valueObject[property.id]}
                                                    width={77}
                                                    height={100}
                                                    scaledWidth={300}
                                                    scaledHeight={400}
                                                    saveOriginalProportion
                                                />
                                                </FormGroup>
                                            </div>
                                        )
                                    case "date":
                                        
                                        return (
                                            <RowWrapper title={property.title}>
                                                <Field 
                                                    id={property.id}
                                                    name={property.id}
                                                    component={({ input }) =>
                                                    <InputTimePicker { ... input }
                                                    timestamp={valueObject && valueObject[property.id]}
                                                    defaultValue={property.default}
                                                    /> }
                                                    style={{ width: '100%' }}
                                                    disabled={disable}
                                                    timestamp={valueObject && valueObject[property.id]}
                                                    />
                                        </RowWrapper>
                                        )
                                    case "custom":
                                        return (property.custom.component)
                                    default:
                                        return (<></>)
                                }
                            })
                            }
                            {submitError &&
                                <div className="bx--row" style={{ position: 'absolute', top: '0', left: '2rem' }}>
                                    <InlineNotification kind='error' lowContrast title="Error" subtitle={submitError} />
                                </div>
                            }
                            </div>
                        </form>
                    </>)
            }}
            />
    );
};


FormContent.propTypes = {
    submit: PropTypes.func.isRequired,
    setTrigger: PropTypes.func.isRequired,
    properties: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        changed: PropTypes.func,
        type: PropTypes.oneOf(["toggle", "text", "textArea", "htmlEditor", "radioButtons", "dropdown", "image", "date", "custom"]).isRequired,
        toggle: PropTypes.shape({
            labelA: PropTypes.string,
            labelB: PropTypes.string
        }),
        text: PropTypes.shape({
            placeHolder: PropTypes.string
        }),
        textArea: PropTypes.shape({
            placeHolder: PropTypes.string
        }),
        radioButtons: PropTypes.shape({
            options: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.string.isRequired,
                labelText: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
                desc: PropTypes.string
            })),
        }),
        dropdown: PropTypes.shape({
            options: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.string.isRequired,
                labelText: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
                desc: PropTypes.string
            })),
        }),
        custom: PropTypes.shape({
            component: PropTypes.any.isRequired
        }),
        convertFrom: PropTypes.func,
        convertTo: PropTypes.func,
        required: PropTypes.bool,
        style: PropTypes.any,
        default: PropTypes.any,
    })).isRequired
};

export default FormContent;
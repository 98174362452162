import axios from 'axios';
import ReactGA from 'react-ga';

import { getAuthHeader } from 'src/modules/api/utils';

export const getComments = async (bookUuid) => {
  try {
    return (await axios.get(`/books/${bookUuid}/comments`, getAuthHeader())).data;
  } catch (e) {
    console.log(e);
  }
};

export const postComment = async (bookUuid, text) => {
  try {
    const data = (await axios.post(`/books/${bookUuid}/comments`, { text }, getAuthHeader())).data;
    ReactGA.event({
      category: 'user',
      action: 'comment'
    });
    return data;
  } catch (e) {
    console.log(e);
  }
};
